import { TeamLogo } from "@/components";
import { apolloGradeColor } from "@/shared/utils/colors";
import {
  PlayerMetadataRow,
  PlayerMetadataScoreRow,
  PlayerRow,
} from "@suns/api/generated-client/apollo";
import { Badge, cn, Flex, Text } from "@suns/design-system";
import dayjs from "dayjs";
import {
  ApolloGradeValueLabels,
  ReportPositionLabels,
  ReportRoleLabels,
  ReportScoreDescriptions,
} from "../reports/reports-const";
import {
  formatMoney,
  numericalApolloGrade,
  plusMinus,
} from "@/shared/utils/helper-functions";
import { CURRENT_SEASON } from "@/shared/const";
import { Column } from "@/components/ColumnFilters/ColumnFilters";

export const sections = [
  {
    grouping: "General",
    accessorKeys: ["currentTeam", "target"],
  },
  {
    grouping: "Evaluation",
    accessorKeys: [
      "grade",
      "gradeValue",
      "analyticGrade",
      "analyticGradeValue",
      "scoutGrade",
      "scoutGradeValue",

      "remainingCapacity",
      "remainingCapacityValue",
      "analyticRemainingCapacity",
      "analyticRemainingCapacityValue",
      "scoutRemainingCapacity",
      "scoutRemainingCapacityValue",

      "position",
      "positionValue",
      "analyticPosition",
      "analyticPositionValue",
      "scoutPosition",
      "scoutPositionValue",

      "role",
      "roleValue",
      "analyticRole",
      "analyticRoleValue",
      "scoutRole",
      "scoutRoleValue",

      "aim",
      "oAim",
      "dAim",

      "ppv",
      "oPpv",
      "dPpv",

      "prv",
    ],
  },
  {
    grouping: "Player Info",
    accessorKeys: [
      "birthDate",
      "height",
      "weight",
      "wing",
      "reach",
      "yearsOfService",
    ],
  },
  // {
  //   grouping: "Scores",
  //   accessorKeys: Object.values(PlayerMetadataScoreRow.key).map((key) => key),
  // },
  {
    grouping: "Salary",
    accessorKeys: [
      "capHit",
      "capHit1Year",
      "capHit2Year",
      "capHit3Year",
      "capHit4Year",
      "aav",
      "unrestrictedFreeAgent",
      "restrictedFreeAgent",
      "playerOption",
      "teamOption",
    ],
  },
];

export const columns: Column<PlayerRow>[] = [
  {
    header: "Player",
    accessorKey: "name",
    accessorFn: (row: PlayerRow) => row.lastName + ", " + row.firstName,
    enableHiding: false,
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) => row.firstName + " " + row.lastName,
  },
  {
    header: "Team",
    accessorKey: "currentTeam",
    cell: (cell) => {
      const teams = cell.row.original.currentTeams;
      return (
        <Flex direction="right" align="center" justify="center" gap="sm">
          {teams?.map((team, i) => (
            <Flex direction="right" align="center" gap="xs" key={team.id}>
              <TeamLogo
                src={team.image}
                nbaTeamId={team.nbaId || team.id}
                leagueId={team.domesticLeagueId}
                size="xxxs"
              />
              <Text size="sm" key={team.id} className="text-nowrap">
                {team.code}
                {i < teams.length - 1 ? "," : ""}
              </Text>
            </Flex>
          ))}
        </Flex>
      );
    },
    enableSorting: true,
    sortDescFirst: false,
    sortingFn: "text",
    csvFn: (row: PlayerRow) =>
      row.currentTeams.map((team) => team.code).join(", "),
  },
  {
    header: "High School",
    accessorKey: "highschool",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) => row.highschool ?? "--",
  },
  {
    header: "Home Town",
    accessorKey: "homeTown",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) => row.homeTown ?? "--",
  },
  {
    header: "Home State",
    accessorKey: "homeState",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) => row.homeState ?? "--",
  },
  {
    header: "Home Country",
    accessorKey: "homeCountryCode",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) => row.homeCountryCode ?? "--",
  },
  {
    header: "Nationality",
    accessorKey: "nationalityCountryCode",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) => row.nationalityCountryCode ?? "--",
  },
  {
    header: "Age",
    accessorKey: "birthDate",
    accessorFn: (row: PlayerRow) =>
      row.birthDate
        ? dayjs().diff(dayjs(row.birthDate), "year", true).toFixed(1)
        : "--",
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.birthDate
        ? dayjs().diff(dayjs(row.birthDate), "year", true).toFixed(1)
        : "--",
  },
  {
    header: "Years of Service",
    accessorKey: "yearsOfService",
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) => row.yearsOfService?.toString() ?? "--",
  },
  {
    header: "Height",
    accessorKey: "height",
    accessorFn: (row: PlayerRow) => {
      if (!row.height) return "--";
      const inches = Number(row.height);
      const feet = Math.floor(inches / 12);
      const remainingInches = inches % 12;
      return `${feet}' ${remainingInches}`;
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.height
        ? `${Math.floor(Number(row.height) / 12)}' ${Number(row.height) % 12}"`
        : "--",
  },
  {
    header: "Weight",
    accessorKey: "weight",
    accessorFn: (row: PlayerRow) => (row.weight ? `${row.weight} lbs` : "--"),
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) => row.weight?.toString() ?? "--",
  },
  {
    header: "Wing",
    accessorKey: "wing",
    accessorFn: (row: PlayerRow) => {
      if (!row.wing) return "--";

      const feet = Math.floor(row.wing / 12);
      const remainingInches = row.wing % 12;
      return `${feet}' ${remainingInches}`;
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.wing ? `${Math.floor(row.wing / 12)}' ${row.wing % 12}` : "--",
  },
  {
    header: "Reach",
    accessorKey: "reach",
    accessorFn: (row: PlayerRow) => {
      if (!row.reach) return "--";

      const feet = Math.floor(row.reach / 12);
      const remainingInches = row.reach % 12;
      return `${feet}' ${remainingInches}`;
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.reach ? `${Math.floor(row.reach / 12)}' ${row.reach % 12}` : "--",
  },
  {
    header: "Target",
    accessorKey: "target",
    enableSorting: true,
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.target === PlayerMetadataRow.target.FREE_AGENT
        ? "Free Agent"
        : row.metadata[0]?.target === PlayerMetadataRow.target.TRADE
          ? "Trade"
          : "--",
    sortingFn: "text",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.target === PlayerMetadataRow.target.FREE_AGENT
        ? "Free Agent"
        : row.metadata[0]?.target === PlayerMetadataRow.target.TRADE
          ? "Trade"
          : "--",
  },
  {
    header: "Apollo Grade",
    accessorKey: "grade",
    cell: (cell) => {
      const grade = cell.row.original.metadata[0]?.hybridGradeCurrent ?? null;
      if (!grade) return "--";

      return (
        <Flex direction="right" gap="sm" align="center" justify="center">
          <Badge
            className={cn(
              "mt-1 h-3 w-3 rounded-full p-0",
              apolloGradeColor(grade.numericalValue)
            )}
          />
          <Text size="sm" className="text-nowrap">
            {ApolloGradeValueLabels[grade.value]}
          </Text>
        </Flex>
      );
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridGradeCurrent?.value
        ? ApolloGradeValueLabels[row.metadata[0].hybridGradeCurrent.value]
        : "--",
  },
  {
    header: "Apollo Grade Value",
    accessorKey: "gradeValue",
    accessorFn: (row: PlayerRow) =>
      numericalApolloGrade(row.metadata[0]?.hybridGradeCurrent?.numericalValue),
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridGradeCurrent?.numericalValue
        ? row.metadata[0].hybridGradeCurrent.numericalValue.toFixed(2)
        : "--",
  },
  {
    header: "Analytic Grade",
    accessorKey: "analyticGrade",
    cell: (cell) => {
      const grade = cell.row.original.metadata[0]?.analyticGradeCurrent ?? null;
      if (!grade) return "--";

      return (
        <Flex direction="right" gap="sm" align="center" justify="center">
          <Badge
            className={cn(
              "mt-1 h-3 w-3 rounded-full p-0",
              apolloGradeColor(grade.numericalValue)
            )}
          />
          <Text size="sm" className="text-nowrap">
            {ApolloGradeValueLabels[grade.value]}
          </Text>
        </Flex>
      );
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.analyticGradeCurrent?.value
        ? ApolloGradeValueLabels[row.metadata[0].analyticGradeCurrent.value]
        : "--",
  },
  {
    header: "Analytic Grade Value",
    accessorKey: "analyticGradeValue",
    accessorFn: (row: PlayerRow) =>
      numericalApolloGrade(
        row.metadata[0]?.analyticGradeCurrent?.numericalValue
      ),
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.analyticGradeCurrent?.numericalValue
        ? row.metadata[0].analyticGradeCurrent.numericalValue.toFixed(2)
        : "--",
  },
  {
    header: "Scout Grade",
    accessorKey: "scoutGrade",
    cell: (cell) => {
      const grade = cell.row.original.metadata[0]?.scoutGradeCurrent ?? null;
      if (!grade) return "--";

      return (
        <Flex direction="right" gap="sm" align="center" justify="center">
          <Badge
            className={cn(
              "mt-1 h-3 w-3 rounded-full p-0",
              apolloGradeColor(grade.numericalValue)
            )}
          />
          <Text size="sm" className="text-nowrap">
            {ApolloGradeValueLabels[grade.value]}
          </Text>
        </Flex>
      );
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutGradeCurrent?.value
        ? ApolloGradeValueLabels[row.metadata[0].scoutGradeCurrent.value]
        : "--",
  },
  {
    header: "Scout Grade Value",
    accessorKey: "scoutGradeValue",
    accessorFn: (row: PlayerRow) =>
      numericalApolloGrade(row.metadata[0]?.scoutGradeCurrent?.numericalValue),
    enableSorting: true,
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutGradeCurrent?.numericalValue
        ? row.metadata[0].scoutGradeCurrent.numericalValue.toFixed(2)
        : "--",
  },
  {
    header: "Remaining Capacity",
    accessorKey: "remainingCapacity",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridGradeRemainingCapacity
        ? ApolloGradeValueLabels[
            row.metadata[0].hybridGradeRemainingCapacity.value
          ]
        : "--",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridGradeRemainingCapacity
        ? ApolloGradeValueLabels[
            row.metadata[0].hybridGradeRemainingCapacity.value
          ]
        : "--",
  },
  {
    header: "Scout Remaining Capacity",
    accessorKey: "scoutRemainingCapacity",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutGradeRemainingCapacity
        ? ApolloGradeValueLabels[
            row.metadata[0].scoutGradeRemainingCapacity.value
          ]
        : "--",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutGradeRemainingCapacity
        ? ApolloGradeValueLabels[
            row.metadata[0].scoutGradeRemainingCapacity.value
          ]
        : "--",
  },
  {
    header: "Position",
    accessorKey: "position",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridPosition
        ? ReportPositionLabels[row.metadata[0].hybridPosition]
        : "--",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridPosition
        ? ReportPositionLabels[row.metadata[0].hybridPosition]
        : "--",
  },
  {
    header: "Scout Position",
    accessorKey: "scoutPosition",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutPosition
        ? ReportPositionLabels[row.metadata[0].scoutPosition]
        : "--",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutPosition
        ? ReportPositionLabels[row.metadata[0].scoutPosition]
        : "--",
  },
  {
    header: "Role",
    accessorKey: "role",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridRole
        ? ReportRoleLabels[row.metadata[0].hybridRole]
        : "--",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridRole
        ? ReportRoleLabels[row.metadata[0].hybridRole]
        : "--",
  },
  {
    header: "Scout Role",
    accessorKey: "scoutRole",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutRole
        ? ReportRoleLabels[row.metadata[0].scoutRole]
        : "--",
    enableSorting: true,
    sortingFn: "text",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.scoutRole
        ? ReportRoleLabels[row.metadata[0].scoutRole]
        : "--",
  },
  {
    header: "AIM",
    accessorKey: "aim",
    accessorFn: (row: PlayerRow) => plusMinus(row.metadata[0]?.aim, 2),
    enableSorting: true,
    sortingFn: "alphanumeric",
    sortDescFirst: true,
    csvFn: (row: PlayerRow) => plusMinus(row.metadata[0]?.aim, 2),
  },
  {
    header: "oAIM",
    accessorKey: "oAim",
    accessorFn: (row: PlayerRow) => plusMinus(row.metadata[0]?.oAim, 2),
    enableSorting: true,
    sortingFn: "alphanumeric",
    sortDescFirst: true,
    csvFn: (row: PlayerRow) => plusMinus(row.metadata[0]?.oAim, 2),
  },
  {
    header: "dAIM",
    accessorKey: "dAim",
    accessorFn: (row: PlayerRow) => plusMinus(row.metadata[0]?.dAim, 2),
    enableSorting: true,
    sortingFn: "alphanumeric",
    sortDescFirst: true,
    csvFn: (row: PlayerRow) => plusMinus(row.metadata[0]?.dAim, 2),
  },
  ...[
    CURRENT_SEASON,
    CURRENT_SEASON + 1,
    CURRENT_SEASON + 2,
    CURRENT_SEASON + 3,
    CURRENT_SEASON + 4,
  ].map((season, i) => ({
    header: `${season.toString().slice(-2)}-${(season + 1).toString().slice(-2)} Cap Hit`,
    accessorKey: `capHit${i > 0 ? `${i}Year` : ""}`,
    accessorFn: (row: PlayerRow) => {
      const key = `capHit${i > 0 ? `${i}Year` : ""}`;
      const salary = row.metadata[0]?.[key as keyof PlayerMetadataRow];
      return salary ? `$${salary.toLocaleString()}` : "--";
    },
    enableSorting: true,
    sortingFn: "alphanumeric" as const,
    sortDescFirst: true,
    csvFn: (row: PlayerRow) => {
      const key = `capHit${i > 0 ? `${i}Year` : ""}`;
      const salary = row.metadata[0]?.[key as keyof PlayerMetadataRow];
      return salary ? `"${formatMoney(salary as number)}"` : "--";
    },
  })),
  ...Object.values(PlayerMetadataScoreRow.key).map((scoreKey) => ({
    header:
      ReportScoreDescriptions[scoreKey].fullLabel ||
      ReportScoreDescriptions[scoreKey].label,
    accessorKey: scoreKey,
    enableSorting: true,
    accessorFn: (row: PlayerRow) => {
      const score = row.metadata[0]?.hybridScores?.find(
        (score) => score.key === scoreKey
      );
      return score == null || score.value == null
        ? "--"
        : `${score.value}/${score.max}`;
    },
    sortingFn: "alphanumeric" as const,
    csvFn: (row: PlayerRow) => {
      const score = row.metadata[0]?.hybridScores?.find(
        (score) => score.key === scoreKey
      );
      return score == null || score.value == null
        ? "--"
        : `${score.value}/${score.max}`;
    },
  })),
  {
    header: "AAV",
    accessorKey: "aav",
    enableSorting: true,
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.aav ? `$${row.metadata[0].aav.toLocaleString()}` : "--",
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) =>
      row.metadata[0]?.aav
        ? `"$${row.metadata[0].aav.toLocaleString()}"`
        : "--",
  },
  {
    header: "UFA",
    accessorKey: "unrestrictedFreeAgent",
    enableSorting: true,
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.unrestrictedFreeAgent ?? "--",
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) => row.metadata[0]?.unrestrictedFreeAgent ?? "--",
  },
  {
    header: "RFA",
    accessorKey: "restrictedFreeAgent",
    enableSorting: true,
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.restrictedFreeAgent ?? "--",
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) => row.metadata[0]?.restrictedFreeAgent ?? "--",
  },
  {
    header: "Player Option",
    accessorKey: "playerOption",
    enableSorting: true,
    accessorFn: (row: PlayerRow) => row.metadata[0]?.playerOption ?? "--",
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) => row.metadata[0]?.playerOption ?? "--",
  },
  {
    header: "Team Option",
    accessorKey: "teamOption",
    enableSorting: true,
    accessorFn: (row: PlayerRow) => row.metadata[0]?.teamOption ?? "--",
    sortingFn: "alphanumeric",
    csvFn: (row: PlayerRow) => row.metadata[0]?.teamOption ?? "--",
  },
  {
    header: "PPV",
    accessorKey: "ppv",
    accessorFn: (row: PlayerRow) => formatMoney(row.metadata[0]?.ppv),
    enableSorting: true,
    sortingFn: "alphanumeric",
    sortDescFirst: true,
    csvFn: (row: PlayerRow) => `"${formatMoney(row.metadata[0]?.ppv)}"`,
  },
  {
    header: "oPPV",
    accessorKey: "oPpv",
    accessorFn: (row: PlayerRow) => formatMoney(row.metadata[0]?.oPpv),
    enableSorting: true,
    sortingFn: "alphanumeric",
    sortDescFirst: true,
    csvFn: (row: PlayerRow) => `"${formatMoney(row.metadata[0]?.oPpv)}"`,
  },
  {
    header: "dPPV",
    accessorKey: "dPpv",
    accessorFn: (row: PlayerRow) => formatMoney(row.metadata[0]?.dPpv),
    enableSorting: true,
    sortingFn: "alphanumeric",
    sortDescFirst: true,
    csvFn: (row: PlayerRow) => `"${formatMoney(row.metadata[0]?.dPpv)}"`,
  },
  {
    header: "PRV",
    accessorKey: "prv",
    accessorFn: (row: PlayerRow) => formatMoney(row.metadata[0]?.prv),
    enableSorting: true,
    sortingFn: "alphanumeric",
    sortDescFirst: true,
    csvFn: (row: PlayerRow) => `"${formatMoney(row.metadata[0]?.prv)}"`,
  },
];
