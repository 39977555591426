import { useMemo } from "react";
import { TeamCacheItem, useTeamMap } from "./useTeamMap";
import { PlayerCacheItem, usePlayerMap } from "./usePlayerMap";
import Fuse from "fuse.js";
import { LeagueId } from "@suns/api";
import { AgentCacheItem, useAgentsMap } from "./useAgentsMap";
import { SearchType } from "@/components/PlayerTeamSearch/PlayerTeamAgentSearch";
type UsePlayerTeamSearchOptions = {
  limit?: number;
  type: SearchType[];
};

interface UsePlayerTeamSearchResponse<
  T = TeamCacheItem | PlayerCacheItem | AgentCacheItem,
> {
  results: T[];
  lookupMap?: Map<string, T>;
  loading: boolean;
}

function usePlayerTeamAgentSearch(
  searchValue: string,
  options: UsePlayerTeamSearchOptions = {
    type: [SearchType.PLAYER, SearchType.TEAM, SearchType.AGENT],
  }
): UsePlayerTeamSearchResponse {
  const { limit = 10, type } = options;
  const {
    response: teamMap,
    loading: teamMapLoading,
    error: teamMapError,
  } = useTeamMap();
  const {
    response: playerMap,
    loading: playerMapLoading,
    error: playerMapError,
  } = usePlayerMap();
  const {
    response: agentMap,
    loading: agentMapLoading,
    error: agentMapError,
  } = useAgentsMap();

  if (teamMapError) {
    throw teamMapError;
  }

  if (playerMapError) {
    throw playerMapError;
  }

  if (agentMapError) {
    throw agentMapError;
  }

  const lookupMap = useMemo(() => {
    if (!teamMap || !playerMap || !agentMap) {
      return;
    }

    const searchItems =
      type?.map((searchType) => {
        switch (searchType) {
          case SearchType.TEAM:
            return teamMap;
          case SearchType.PLAYER:
            return playerMap;
          case SearchType.AGENT:
            return agentMap;
          default:
            return new Map();
        }
      }) || [];

    return new Map<string, PlayerCacheItem | TeamCacheItem | AgentCacheItem>(
      searchItems.flatMap((map) => Array.from(map))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerMap, teamMap, agentMap, type.toString()]);

  const searchItems = useMemo(() => {
    if (!lookupMap) {
      return;
    }
    return Array.from(lookupMap.values());
  }, [lookupMap]);

  const fuse = useMemo(() => {
    if (!searchItems) {
      return;
    }

    return new Fuse(searchItems, {
      includeScore: true,
      ignoreFieldNorm: true,
      ignoreLocation: true,
      keys: ["fullName", { name: "teamName", weight: 2 }],
    });
  }, [searchItems]);

  const results = useMemo(() => {
    if (!fuse || !searchItems) {
      return [];
    }

    if (searchValue.length < 2) {
      return searchItems.slice(0, limit);
    }

    const leagueMultipliers: Partial<Record<LeagueId, number>> = {
      [LeagueId.NBA]: 0.05,
      [LeagueId.NCAA]: 0.1,
      [LeagueId.GLEAGUE]: 0.15,
    };

    const results = fuse
      .search(searchValue, { limit })
      .map((result) => {
        const leagueMultiplier =
          result.item.type !== "agent"
            ? leagueMultipliers[result.item.leagueId]
            : 0.175;
        if (result.score && leagueMultiplier) {
          return {
            ...result,
            score: result.score * leagueMultiplier,
          };
        }
        return result;
      })
      .sort((a, b) => (a.score ?? 0) - (b.score ?? 0));

    return results.map((result) => result.item);
  }, [fuse, searchValue, limit, searchItems]);

  const loading = teamMapLoading || playerMapLoading || agentMapLoading;

  if (!loading && !lookupMap) {
    throw new Error(
      "The lookup map for player/team/agent search failed to load."
    );
  }

  return {
    results,
    lookupMap,
    loading,
  };
}

export { usePlayerTeamAgentSearch };
