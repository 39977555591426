import { TeamRow } from "@suns/api/generated-client/apollo";
import { Column } from "@/components/ColumnFilters/ColumnFilters";

export const sections = [
  {
    grouping: "General",
    accessorKeys: ["domesticLeagueId", "conference"],
  },
];

export const columns: Column<TeamRow>[] = [
  {
    header: "Team",
    accessorKey: "fullName",
    enableSorting: true,
    enableHiding: false,
    sortingFn: "text",
    sortDescFirst: false,
    csvFn: (row: TeamRow) => row.fullName ?? "--",
  },
  {
    header: "League",
    accessorKey: "domesticLeagueId",
    cell: (cell) => {
      return cell.row.original.domesticLeague?.name ?? "--";
    },
    enableSorting: true,
    sortingFn: "text",
    sortDescFirst: false,
    csvFn: (row: TeamRow) => row.domesticLeagueId ?? "--",
  },
  {
    header: "Conference",
    accessorKey: "conference",
    cell: (cell) => {
      return cell.row.original.conference ?? "--";
    },
    enableSorting: true,
    sortingFn: "text",
    sortDescFirst: false,
    csvFn: (row: TeamRow) => row.conference ?? "--",
  },
  // {
  //   header: "Total Cap",
  //   accessorKey: "cap",
  //   enableSorting: true,
  //   sortingFn: "text",
  //   csvFn: (row: TeamRow) => row.totalCap ?? "--",
  // },
  // {
  //   header: "Cap Space",
  //   accessorKey: "capSpace",
  //   enableSorting: true,
  //   sortingFn: "text",
  //   csvFn: (row: TeamRow) => row.capSpace ?? "--",
  // },
  // {
  //   header: "Cap Status",
  //   accessorKey: "capStatus",
  //   enableSorting: true,
  //   sortingFn: "text",
  //   csvFn: (row: TeamRow) => row.capStatus ?? "--",
  // },
  // {
  //   header: "Net Rating",
  //   accessorKey: "netRating",
  //   enableSorting: true,
  //   sortingFn: "text",
  //   csvFn: (row: TeamRow) => row.netRating ?? "--",
  // },
];
