import { useState } from "react";
import { StatsTable } from "../../../components/StatsTable/StatsTable";
import { Flex, Text } from "@suns/design-system";
import { PlayerRow } from "@suns/api/generated-client/apollo";
import { StatParams, usePlayerStats } from "../hooks/usePlayerStats";
import { CURRENT_SEASON_LABEL } from "@/shared/const";

export interface Stats {
  stats: (string | number | null)[];
  statsHeaders: string[];
  error?: string;
}

interface StatsTableConfig {
  title: string;
  stats: Stats[];
  loading: boolean;
  error: Error | null;
  measureTypeKey: string;
  perModeKey?: string;
  additionalProps?: {
    playerLastNGames?: string;
    setPlayerLastNGames?: (value: string) => void;
    seasonType?: string;
    setSeasonType?: (value: string) => void;
  };
}

export function PlayersStats({ player }: { player: PlayerRow }) {
  const seasons = "All";

  const [statParams, setStatParams] = useState({
    measureTypeNBARegularSeason: "Base",
    perModeNBARegularSeason: "PerGame",
    measureTypeNBAPlayoffs: "Base",
    perModeNBAPlayoffs: "PerGame",
    measureTypeGLeagueRegularSeason: "Base",
    perModeGLeagueRegularSeason: "PerGame",
    measureTypeGLeaguePlayoffs: "Base",
    perModeGLeaguePlayoffs: "PerGame",
    measureTypeGLeagueShowcase: "Base",
    perModeGLeagueShowcase: "PerGame",
    measureTypeNbaGames: "Base",
    playerLastNGamesNba: "5",
    nbaGamesSeasonType: "Regular Season",
    measureTypeGLeagueGames: "Base",
    gLeaguePlayerLastNGames: "5",
    gLeagueGamesSeasonType: "Regular Season",
  });

  const nbaId = player.nbaId ?? undefined;

  const {
    nbaRegularSeasonStats,
    nbaPlayoffStats,
    gLeagueRegularSeasonStats,
    gLeagueStatsPlayoffs,
    nbaGameStats,
    gLeagueGameStats,
  } = usePlayerStats({
    statParams: statParams as StatParams,
    seasons,
    playerId: nbaId,
    apolloLeagueIds: player.currentTeams?.map((team) => team.domesticLeagueId),
    currentSeason: CURRENT_SEASON_LABEL,
  });

  const handleStatsChange = (key: string, value: string) => {
    if (key === "nbaGamesSeasonType" && value === "IST") {
      setStatParams((prev) => ({
        ...prev,
        playerLastNGamesNba: "0",
        nbaGamesSeasonType: "IST",
      }));
    } else {
      setStatParams((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const statsConfigs: StatsTableConfig[] = [
    {
      title: "NBA Game Stats",
      stats: (nbaGameStats.response ?? []) as Stats[],
      loading: nbaGameStats.loading,
      error: nbaGameStats.error,
      measureTypeKey: "NbaGames",
      additionalProps: {
        playerLastNGames: statParams.playerLastNGamesNba,
        setPlayerLastNGames: (value) =>
          handleStatsChange("playerLastNGamesNba", value),
        seasonType: statParams.nbaGamesSeasonType,
        setSeasonType: (value) =>
          handleStatsChange("nbaGamesSeasonType", value),
      },
    },

    {
      title: "NBA Regular Season Stats",
      stats: (nbaRegularSeasonStats.response ?? []) as Stats[],
      loading: nbaRegularSeasonStats.loading,
      error: nbaRegularSeasonStats.error,
      measureTypeKey: "NBARegularSeason",
      perModeKey: "NBARegularSeason",
    },

    {
      title: "NBA Playoffs Stats",
      stats: (nbaPlayoffStats.response ?? []) as Stats[],
      loading: nbaPlayoffStats.loading,
      error: nbaPlayoffStats.error,
      measureTypeKey: "NBAPlayoffs",
      perModeKey: "NBAPlayoffs",
    },

    {
      title: "G League Game Stats",
      stats: (gLeagueGameStats.response ?? []) as Stats[],
      loading: gLeagueGameStats.loading,
      error: gLeagueGameStats.error,
      measureTypeKey: "GLeagueGames",
      additionalProps: {
        playerLastNGames: statParams.gLeaguePlayerLastNGames,
        setPlayerLastNGames: (value) =>
          handleStatsChange("gLeaguePlayerLastNGames", value),
        seasonType: statParams.gLeagueGamesSeasonType,
        setSeasonType: (value) =>
          handleStatsChange("gLeagueGamesSeasonType", value),
      },
    },

    {
      title: "G League Season Stats",
      stats: (gLeagueRegularSeasonStats.response ?? []) as Stats[],
      loading: gLeagueRegularSeasonStats.loading,
      error: gLeagueRegularSeasonStats.error,
      measureTypeKey: "GLeagueRegularSeason",
      perModeKey: "GLeagueRegularSeason",
    },

    {
      title: "G League Playoffs Stats",
      stats: (gLeagueStatsPlayoffs.response ?? []) as Stats[],
      loading: gLeagueStatsPlayoffs.loading,
      error: gLeagueStatsPlayoffs.error,
      measureTypeKey: "GLeaguePlayoffs",
      perModeKey: "GLeaguePlayoffs",
    },
  ];

  return (
    <Flex direction="down" gap="md">
      {nbaId ? (
        <>
          {statsConfigs.map((stats) => (
            <StatsTable
              key={stats.title}
              title={stats.title}
              loading={stats.loading}
              error={stats.error ? true : null}
              stats={stats.stats}
              measureType={
                statParams[
                  `measureType${stats.measureTypeKey}` as keyof typeof statParams
                ]
              }
              setMeasureType={(value) =>
                handleStatsChange(`measureType${stats.measureTypeKey}`, value)
              }
              perMode={
                stats.perModeKey
                  ? statParams[
                      `perMode${stats.perModeKey}` as keyof typeof statParams
                    ]
                  : undefined
              }
              setPerMode={
                stats.perModeKey
                  ? (value) =>
                      handleStatsChange(`perMode${stats.perModeKey}`, value)
                  : undefined
              }
              {...stats.additionalProps}
            />
          ))}
        </>
      ) : (
        <>
          <Text size="lg" heading muted className="py-4 text-center">
            No stats available
          </Text>
        </>
      )}
    </Flex>
  );
}
