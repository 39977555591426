import { useAsync } from "@/shared/hooks/useAsync";
import { SunsApiError } from "@suns/api";
import { agenciesLoader, agentsLoader } from "./loaders/agency-loader";
import { AgencyRow, AgentRow } from "@suns/api/generated-client/apollo";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "@suns/design-system/src/components/DataTable/DataTable";
import { Filter, Flex } from "@suns/design-system";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMemo } from "react";

const columns: ColumnDef<AgentRow>[] = [
  {
    header: "Name",
    accessorKey: "name",
    enableHiding: false,
    enableSorting: false,
  },
  {
    header: "Agency",
    accessorKey: "agency",
    accessorFn: (row: AgentRow) => row?.agency?.name || "--",
    enableHiding: false,
    enableSorting: false,
  },
  {
    header: "Players",
    accessorKey: "players",
    accessorFn: (row: AgentRow) => row?.players?.length || 0,
    sortingFn: (rowA, rowB) =>
      (rowA.original.players?.length || 0) -
      (rowB.original.players?.length || 0),
    enableHiding: false,
    enableSorting: false,
  },
  {
    header: "Mobile Phone#",
    accessorKey: "mobilePhone",
    accessorFn: (row: AgentRow) => (row.mobilePhone ? row.mobilePhone : "--"),
    enableHiding: false,
    enableSorting: false,
  },
  {
    header: "Work Phone#",
    accessorKey: "workPhone",
    accessorFn: (row: AgentRow) => (row.workPhone ? row.workPhone : "--"),
    enableHiding: false,
    enableSorting: false,
  },
  {
    header: "Email",
    accessorKey: "email",
    accessorFn: (row: AgentRow) => (row.email ? row.email : "--"),
    enableHiding: false,
    enableSorting: false,
  },
];

export function AgentListing() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    pageSize: "20",
    sort: "name",
    dir: "desc",
  });

  const sorting = [
    {
      id: searchParams.get("sort") || "name",
      desc: searchParams.get("dir") === "asc",
    },
  ];

  const pagination = {
    pageIndex: parseInt(searchParams.get("page") || "1") - 1,
    pageSize: parseInt(searchParams.get("pageSize") || "20"),
  };

  const agencyIdParams = searchParams.get("agencyId");
  const agencyIds = agencyIdParams?.split(",");

  const {
    response: agents,
    loading: agentsLoading,
    error: agentsError,
  } = useAsync(agentsLoader, {
    offset: pagination.pageIndex * pagination.pageSize,
    limit: pagination.pageSize,
    sortColumn: sorting[0].id,
    sortDir: sorting[0].desc ? "DESC" : "ASC",
    agencyId: agencyIds,
  });

  const {
    response: agencies,
    loading: agenciesLoading,
    error: agenciesError,
  } = useAsync(agenciesLoader);

  const handleSelectAgent = (id: number | null) => {
    if (!id) return;
    navigate(`${id}`);
  };

  const updateSearchParams = (params = {}) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      ...params,
    });
  };

  const handleFilterChange = (selectedKeys: string[]) => {
    if (selectedKeys.length === 0) {
      const { agencyId, ...rest } = Object.fromEntries(searchParams);
      setSearchParams(rest);
    } else {
      updateSearchParams({
        agencyId: selectedKeys.join(","),
      });
    }
  };

  const filterAgencyOptions = useMemo(
    () =>
      agencies?.agencies.map((agency: AgencyRow) => ({
        key: agency.id.toString(),
        name: agency.name,
      })) || [],
    [agencies?.agencies]
  );

  if (agentsError) {
    throw new SunsApiError("Error loading agents", {
      cause: agentsError,
    });
  }

  if (agenciesError) {
    throw new SunsApiError("Error loading agencies", {
      cause: agenciesError,
    });
  }

  return (
    <Flex direction="down" gap="md">
      <Flex>
        <Filter
          title={agenciesLoading ? "Loading..." : "Agency"}
          value={agencyIds ?? []}
          options={filterAgencyOptions}
          onChange={handleFilterChange}
        />
      </Flex>
      <DataTable
        loading={agentsLoading}
        columns={columns}
        data={agents?.agents || []}
        count={agents?.count || 0}
        pagination={pagination}
        setPagination={(setPagination) => {
          const newPagination =
            typeof setPagination === "function"
              ? setPagination(pagination)
              : setPagination;

          updateSearchParams({
            page: `${newPagination.pageIndex + 1}`,
            pageSize: `${newPagination.pageSize}`,
          });
        }}
        onRowClicked={(row) => handleSelectAgent(row.original.id)}
      />
    </Flex>
  );
}
