import { apolloApi } from "@/shared/api";
import { LeagueId } from "@suns/api";
import { useAsync } from "./useAsync";
import { TeamRow } from "@suns/api/generated-client/apollo";

type TeamMap = Map<string, TeamCacheItem>;

// Bump this manually to force clear client cache
const TEAM_LIST_CACHE_VERSION = 2;
const TEAM_LIST_CACHE_KEY = "teamListCache";
let TEAM_LIST_PROMISE: Promise<TeamMap> | null = null;
const CACHE_TTL = 7 * 24 * 60 * 60 * 1000; // 7 days

export type TeamCacheItem = {
  type: "team";
  key: string;
  id: number;
  teamName: string;
  display: string;
  leagueId: LeagueId;
};

function getTeamsFromCache() {
  const cacheString = localStorage.getItem(TEAM_LIST_CACHE_KEY);

  if (!cacheString) {
    return;
  }
  const cache = JSON.parse(cacheString);
  const now = new Date().getTime();

  if (
    cache.version !== TEAM_LIST_CACHE_VERSION ||
    now - cache.date > CACHE_TTL
  ) {
    localStorage.removeItem(TEAM_LIST_CACHE_KEY);
    return;
  }

  return new Map(cache.teams) as TeamMap;
}

let teamMapFromCache = getTeamsFromCache();

export function useTeamMap() {
  return useAsync(async () => {
    if (teamMapFromCache) {
      return teamMapFromCache;
    }

    if (TEAM_LIST_PROMISE) {
      return TEAM_LIST_PROMISE;
    }

    TEAM_LIST_PROMISE = apolloApi
      // .getTeams({})
      .getTeams({ limit: 1000 })
      .then((response) => {
        const teamsTuple = response.teams
          .filter((team) => team.domesticLeagueId !== LeagueId.WNBA)
          .sort(sortTeams)
          .map<[string, TeamCacheItem]>((team) => {
            return [
              `team-${team.id}`,
              {
                type: "team" as const,
                key: `team-${team.id}`,
                id: team.id,
                teamName: team.fullName ?? team.name,
                display: team.fullName ?? team.name,
                leagueId: team.domesticLeagueId,
              },
            ];
          });

        localStorage.setItem(
          TEAM_LIST_CACHE_KEY,
          JSON.stringify({
            version: TEAM_LIST_CACHE_VERSION,
            date: new Date().getTime(),
            teams: teamsTuple,
          })
        );

        teamMapFromCache = new Map(teamsTuple);
        return teamMapFromCache;
      })
      .finally(() => {
        TEAM_LIST_PROMISE = null;
      });

    return TEAM_LIST_PROMISE;
  });
}

function sortTeams(team1: TeamRow, team2: TeamRow) {
  const isNBA1 = team1.domesticLeagueId === LeagueId.NBA;
  const isNBA2 = team2.domesticLeagueId === LeagueId.NBA;
  const isNCAA1 = team1.domesticLeagueId === LeagueId.NCAA;
  const isNCAA2 = team2.domesticLeagueId === LeagueId.NCAA;
  const isGLEAGUE1 = team1.domesticLeagueId === LeagueId.GLEAGUE;
  const isGLEAGUE2 = team2.domesticLeagueId === LeagueId.GLEAGUE;
  if (isNBA1 && !isNBA2) return -1;
  if (isNBA2 && !isNBA1) return 1;
  if (isNCAA1 && !isNCAA2) return -1;
  if (isNCAA2 && !isNCAA1) return 1;
  if (isGLEAGUE1 && !isGLEAGUE2) return -1;
  if (isGLEAGUE2 && !isGLEAGUE1) return 1;
  return (team1.fullName || team1.name).localeCompare(
    team2.fullName || team2.name
  );
}
