import {
  Filter,
  Flex,
  Switch,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
} from "@suns/design-system";
import { PlayersReportListings } from "./PlayersReportListings";
import {
  IntelTag,
  PlayerRow,
  ReportQueryParams,
} from "@suns/api/generated-client/apollo";
import { PlayersAndTeamsIntelListings } from "./PlayersAndTeamsIntelListings";
import { PlayersStats } from "./PlayersStats";
import { PlayersIntelTagsLabels } from "../players-const";
import { useState } from "react";
import { useAccount, useAsync } from "@/shared/hooks";
import PlayerContracts from "./PlayerContracts";
import { PlayerAnalytics } from "./PlayerAnalytics";
import { allAuthorUsernamesLoader } from "@/pages/reports/reports-listings/loaders/all-reports-loader";
import { SunsApiError } from "@suns/api";
import { PlayerScouting } from "./PlayerScouting";

export function PlayerInfoSection({ player }: { player: PlayerRow }) {
  const [reportType, setReportType] = useState<
    ReportQueryParams.type.PHASE | "All"
  >(ReportQueryParams.type.PHASE);
  const [tags, setTags] = useState<string[]>([]);
  const [selectedReportAuthors, setSelectedReportAuthors] = useState<string[]>(
    []
  );
  const [selectedIntelAuthors, setSelectedIntelAuthors] = useState<string[]>(
    []
  );
  const { hasScoutAdminAccess, hasAdminAccess } = useAccount();

  const tabOptions = ["Stats"];

  if (hasAdminAccess || hasScoutAdminAccess) {
    tabOptions.unshift("Overview", "Scouting");
  }

  if (hasAdminAccess) {
    tabOptions.push("Contracts");
  }

  if (hasScoutAdminAccess) {
    tabOptions.push("Reports", "Intel");
  }

  function handleReportsFilterCheckboxChange() {
    setReportType((prev) =>
      prev === ReportQueryParams.type.PHASE
        ? "All"
        : ReportQueryParams.type.PHASE
    );
  }

  function handleReportsFilterChange(values: string[]) {
    setTags(values);
  }

  const intelTagOptions = Object.values(IntelTag).map((tag) => ({
    key: tag,
    name: PlayersIntelTagsLabels[tag],
    value: tag,
  }));

  const {
    response: reportsAuthorsResponse,
    loading: reportsAuthorsLoading,
    error: reportsAuthorsError,
  } = useAsync(allAuthorUsernamesLoader);

  const reportsAuthors = reportsAuthorsResponse?.map((author) => ({
    key: author.authorUsername,
    name: author.authorName,
  }));

  if (reportsAuthorsError) {
    throw new SunsApiError("Error loading the report authors.", {
      cause: reportsAuthorsError,
    });
  }

  return (
    <Tabs defaultValue={tabOptions[0]}>
      {tabOptions.length > 1 && (
        <TabsList className="mb-4">
          {tabOptions.map((tab) => (
            <TabsTrigger key={tab} value={tab} className="px-2 md:px-3">
              {tab}
            </TabsTrigger>
          ))}
        </TabsList>
      )}
      <TabsContent value="Overview">
        <PlayerAnalytics player={player} />
      </TabsContent>
      <TabsContent value="Scouting">
        <PlayerScouting player={player} />
      </TabsContent>
      <TabsContent value="Stats" className="w-full">
        <PlayersStats player={player} />
      </TabsContent>
      <TabsContent value="Contracts">
        <PlayerContracts player={player} />
      </TabsContent>
      <TabsContent value="Reports">
        <Flex direction="right" align="center" gap="md" className="mb-4">
          <Flex direction="right" align="center" gap="sm">
            <Switch
              id="phase-mode"
              checked={reportType === ReportQueryParams.type.PHASE}
              onCheckedChange={handleReportsFilterCheckboxChange}
            />
            <Text size="xs">Only Phase Reports</Text>
          </Flex>
          <Filter
            avoidCollisions={false}
            title={reportsAuthorsLoading ? "Loading..." : "Author"}
            value={selectedReportAuthors || []}
            onChange={(value) => setSelectedReportAuthors(value as string[])}
            options={
              reportsAuthors?.map((author) => ({
                key: author.key,
                name: author.name,
              })) ?? []
            }
          />
        </Flex>
        <PlayersReportListings
          reportType={reportType}
          authorUsername={selectedReportAuthors}
        />
      </TabsContent>
      <TabsContent value="Intel">
        <Flex direction="right" align="center" gap="md" className="mb-4">
          <Filter
            value={tags}
            title="Tags"
            onChange={handleReportsFilterChange}
            options={intelTagOptions}
          />
          <Filter
            avoidCollisions={false}
            title={reportsAuthorsLoading ? "Loading..." : "Author"}
            value={selectedIntelAuthors || []}
            onChange={(value) => setSelectedIntelAuthors(value as string[])}
            options={
              reportsAuthors?.map((author) => ({
                key: author.key,
                name: author.name,
              })) ?? []
            }
          />
        </Flex>
        <PlayersAndTeamsIntelListings
          tags={tags}
          setTags={setTags}
          authorUsername={selectedIntelAuthors}
        />
      </TabsContent>
    </Tabs>
  );
}
