import { Page, TeamLogo } from "@/components";
import {
  Badge,
  Button,
  Card,
  cn,
  Container,
  Flex,
  Grid,
  Skeleton,
  Tabs,
  TabsContent,
  Text,
} from "@suns/design-system";
import { MailIcon, PhoneIcon } from "@suns/design-system/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAsync, useNavigateLogin } from "@/shared/hooks";
import { SunsApiError } from "@suns/api";
import AgentInfoForm from "./components/AgentInfoForm";
import { useState } from "react";
import {
  AgentResponse,
  PlayerMetadataRow,
  PlayerRow,
} from "@suns/api/generated-client/apollo";
import { toast } from "@/shared/utils/toaster";
import { apolloApi } from "@/shared/api";
import { ToastType } from "@/shared/utils/toaster";
import dayjs from "dayjs";
import { Column } from "@/components/ColumnFilters/ColumnFilters";
import { DataTable } from "@suns/design-system/src/components/DataTable/DataTable";
import {
  ApolloGradeValueLabels,
  ReportPositionLabels,
  ReportRoleLabels,
} from "../reports/reports-const";
import { apolloGradeColor } from "@/shared/utils/colors";

import { SortingState } from "@tanstack/react-table";
import { agentByIdLoader } from "./loaders/agency-loader";
import { playersLoader } from "../players/loaders/player-loader";
import { usePrompt } from "@/components/Prompt/Prompt";

export interface AgentInfo {
  email: string | undefined;
  mobilePhone: string | undefined;
  workPhone: string | undefined;
}

const columns: Column<PlayerRow>[] = [
  {
    header: "Player",
    accessorKey: "name",
    accessorFn: (row: PlayerRow) => row.lastName + ", " + row.firstName,
    enableHiding: false,
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "Team",
    accessorKey: "currentTeam",
    cell: (cell) => {
      const teams = cell.row.original.currentTeams || [];
      return (
        <Flex direction="right" align="center" justify="center" gap="sm">
          {teams.length > 0 ? (
            <>
              {teams?.map((team, i) => (
                <Flex direction="right" align="center" gap="xs">
                  <TeamLogo
                    src={team.image}
                    nbaTeamId={team.nbaId}
                    leagueId={team.domesticLeagueId}
                    size="xxxs"
                  />
                  <Text size="sm" key={team.id} className="text-nowrap">
                    {team.code ?? team.name ?? "--"}
                    {i < teams.length - 1 ? "," : ""}
                  </Text>
                </Flex>
              ))}
            </>
          ) : (
            "--"
          )}
        </Flex>
      );
    },
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "Position",
    accessorKey: "position",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridPosition
        ? ReportPositionLabels[row.metadata[0].hybridPosition]
        : "--",
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "Apollo Grade",
    accessorKey: "grade",
    cell: (cell) => {
      const grade = cell.row.original.metadata[0]?.hybridGradeCurrent ?? null;
      if (!grade) return "--";

      return (
        <Flex direction="right" gap="sm" align="center" justify="center">
          <Badge
            className={cn(
              "mt-1 h-3 w-3 rounded-full p-0",
              apolloGradeColor(grade.numericalValue)
            )}
          />
          <Text size="sm" className="text-nowrap">
            {ApolloGradeValueLabels[grade.value]}
          </Text>
        </Flex>
      );
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
  {
    header: "Role",
    accessorKey: "role",
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.hybridRole
        ? ReportRoleLabels[row.metadata[0].hybridRole]
        : "--",
    enableSorting: true,
    sortingFn: "text",
  },
  {
    header: "AAV",
    accessorKey: "aav",
    enableSorting: true,
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.aav ? `$${row.metadata[0].aav.toLocaleString()}` : "--",
    sortingFn: "alphanumeric",
  },
  {
    header: "Age",
    accessorKey: "birthDate",
    accessorFn: (row: PlayerRow) =>
      row.birthDate
        ? dayjs().diff(dayjs(row.birthDate), "year", true).toFixed(1)
        : "--",
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
  {
    header: "Height",
    accessorKey: "height",
    accessorFn: (row: PlayerRow) => {
      if (!row.height) return "--";
      const inches = Number(row.height);
      const feet = Math.floor(inches / 12);
      const remainingInches = inches % 12;
      return `${feet}' ${remainingInches}`;
    },
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
  {
    header: "Weight",
    accessorKey: "weight",
    accessorFn: (row: PlayerRow) => (row.weight ? `${row.weight} lbs` : "--"),
    enableSorting: true,
    sortingFn: "alphanumeric",
  },
  {
    header: "Target",
    accessorKey: "target",
    enableSorting: true,
    accessorFn: (row: PlayerRow) =>
      row.metadata[0]?.target === PlayerMetadataRow.target.FREE_AGENT
        ? "Free Agent"
        : row.metadata[0]?.target === PlayerMetadataRow.target.TRADE
          ? "Trade"
          : "--",
    sortingFn: "text",
  },
];

export function AgentView() {
  const navigateLogin = useNavigateLogin();
  const { agentId } = useParams();
  const navigate = useNavigate();
  const [sortingColumns, setSortingColumns] = useState<SortingState>([
    {
      id: "name",
      desc: false,
    },
  ]);
  const [paginationValues, setPaginationValues] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const pagination = {
    pageIndex: paginationValues.pageIndex,
    pageSize: paginationValues.pageSize,
  };

  const {
    response: agent,
    loading: agentLoading,
    error: agentError,
  } = useAsync(agentByIdLoader, {
    id: Number(agentId),
  });

  const { response, loading } = useAsync(playersLoader, {
    agentId: Number(agentId),
    offset: pagination.pageIndex * pagination.pageSize,
    limit: pagination.pageSize,
    sortColumn: sortingColumns[0].id,
    sortDir: sortingColumns[0].desc ? "DESC" : "ASC",
  });

  const [agentResponse, setAgentResponse] = useState<AgentResponse | null>(
    null
  );
  const [saving, setSaving] = useState(false);
  const prompt = usePrompt();

  const handleEmailClick = () => {
    window.open(`mailto:${agentResponse?.agent.email ?? agent?.agent.email}`);
  };

  const handlePhoneClick = (phone: string) => {
    window.open(`tel:${formatPhoneNumber(phone)}`);
  };

  const handleClickUpsertAgent = async (agentFormData: AgentInfo) => {
    const confirmation = await prompt(
      "Are you sure you want to update this agent?",
      ""
    );

    if (confirmation) {
      try {
        setSaving(true);
        const updatedAgent = await apolloApi.upsertAgent(agentFormData);

        setAgentResponse(updatedAgent);

        toast(ToastType.SUCCESS, "Agent Updated");
      } catch (error) {
        if (error instanceof SunsApiError && error.authError) {
          toast(ToastType.ERROR, "Please log back in to update.");
          navigateLogin();
        }
        toast(ToastType.ERROR, "Unable to update agent. Please try again.");
      } finally {
        setSaving(false);
      }
    }
  };

  const handleSelectPlayer = (id: number | null) => {
    if (!id) return;
    navigate(`/players/${id}`);
  };

  if (agentError) {
    throw new SunsApiError("Error loading agent", {
      cause: agentError,
    });
  }

  return (
    <Page
      title={agent?.agent.name}
      loadingState={<AgentViewLoading />}
      breadcrumbPaths={[
        { label: "Players", link: "/players" },
        { label: "Agents", link: "/players/agents" },
      ]}
      render={() => {
        return (
          <Container size="xl">
            <Flex direction="down" gap="md">
              {!agentLoading ? (
                <>
                  <Card>
                    <Flex direction="down" gap="md">
                      <Flex direction="down" gap="xs">
                        <Text heading size="2xl">
                          {agent?.agent.name}
                        </Text>
                        <Link
                          className="text-sm font-bold text-primary"
                          to={`/players/agents?agencyId=${agent?.agent.agency?.id}`}
                          state={{
                            agencyId: agent?.agent.agency?.id,
                          }}
                        >
                          {agent?.agent.agency?.name}
                        </Link>
                      </Flex>
                      <Flex gap="md">
                        <Button
                          className="max-sm:w-1/3"
                          onClick={() =>
                            handlePhoneClick(
                              agentResponse
                                ? agentResponse?.agent.mobilePhone
                                : agent?.agent.mobilePhone ?? ""
                            )
                          }
                        >
                          <PhoneIcon className="mr-2 size-4" />
                          <Text size="sm">Mobile</Text>
                        </Button>
                        <Button
                          variant="muted"
                          className="max-sm:w-1/3"
                          onClick={() =>
                            handlePhoneClick(
                              agentResponse
                                ? agentResponse?.agent.workPhone
                                : agent?.agent.workPhone ?? ""
                            )
                          }
                        >
                          <PhoneIcon className="mr-2 size-4" />
                          <Text size="sm">Work</Text>
                        </Button>
                        <Button
                          variant="muted"
                          className="max-sm:w-1/3"
                          onClick={handleEmailClick}
                        >
                          <MailIcon className="mr-2 size-4" />
                          <Text size="sm">Email</Text>
                        </Button>
                      </Flex>
                      {agent?.agent && (
                        <AgentInfoForm
                          agent={agentResponse?.agent ?? agent?.agent}
                          saving={saving}
                          onUpsert={handleClickUpsertAgent}
                        />
                      )}
                    </Flex>
                  </Card>
                </>
              ) : (
                <AgentViewLoading />
              )}
              <Card>
                <Tabs defaultValue="players">
                  <TabsContent value="players">
                    <DataTable
                      loading={loading}
                      columns={columns}
                      data={response?.players || []}
                      count={response?.count || 0}
                      pagination={pagination}
                      setPagination={(setPagination) => {
                        const newPagination =
                          typeof setPagination === "function"
                            ? setPagination(pagination)
                            : setPagination;

                        setPaginationValues(newPagination);
                      }}
                      sorting={sortingColumns}
                      setSorting={(setSorting) => {
                        const newSort =
                          typeof setSorting === "function"
                            ? setSorting(sortingColumns)
                            : setSorting;

                        const sort = newSort[0] ?? null;

                        setSortingColumns([
                          {
                            id: sort.id,
                            desc: sort.desc,
                          },
                        ]);
                      }}
                      onRowClicked={(row) =>
                        handleSelectPlayer(row.original.id)
                      }
                    />
                  </TabsContent>
                </Tabs>
              </Card>
            </Flex>
          </Container>
        );
      }}
    />
  );
}

function AgentViewLoading() {
  return (
    <Container size="xl">
      <Flex direction="down" gap="md">
        <Card>
          <Flex direction="down" gap="md">
            <Flex direction="down" gap="sm">
              <Skeleton className="h-8 w-72" />
              <Skeleton className="h-4 w-48" />
            </Flex>
            <Flex gap="md">
              <Skeleton className="h-10 w-24" />
              <Skeleton className="h-10 w-24" />
              <Skeleton className="h-10 w-24" />
            </Flex>
            <Grid columns="3" gap="sm">
              <Skeleton className="h-10 w-full" />
              <Skeleton className="h-10 w-full" />
              <Skeleton className="h-10 w-full" />
            </Grid>
          </Flex>
        </Card>
      </Flex>
    </Container>
  );
}

export function formatPhoneNumber(value: string | undefined): string {
  if (!value) return "";

  const digits = value.replace(/\D/g, "");

  if (digits.length === 0) {
    return "";
  }

  if (digits.length <= 3) {
    return `(${digits}`;
  }

  if (digits.length <= 6) {
    return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
  }

  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
}
