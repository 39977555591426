import { useNavigate, useSearchParams } from "react-router-dom";
import { Badge, Button, Flex, Text } from "@suns/design-system";
import { useAsync } from "@/shared/hooks";
import { PlayerRow } from "@suns/api/generated-client/apollo";
import { DataTable } from "@suns/design-system/src/components/DataTable/DataTable";
import { VisibilityState } from "@tanstack/react-table";
import { useState } from "react";
import { Sheet, Page } from "@/components";
import { LoaderCircle, SlidersVerticalIcon } from "lucide-react";
import { PlayerFilters } from "./components";
import { ColumnFilters } from "@/components/ColumnFilters/ColumnFilters";
import { playersLoader } from "./loaders/player-loader";
import CsvDownloader from "react-csv-downloader";
import { apolloApi } from "@/shared/api";
import { toast } from "@/shared/utils/toaster";
import { ToastType } from "@/shared/utils/toaster";
import { SunsApiError } from "@suns/api/src/error";
import { columns, sections } from "./players-columns";

const defaultVisibleColumns = [
  "name",
  "currentTeam",
  "position",
  "grade",
  "aim",
  "capHit",
  "birthDate",
];

const defaultParams = {
  page: "1",
  pageSize: "20",
  sort: "name",
  dir: "asc",
  visible: defaultVisibleColumns.join(","),
  "league.in": "1",
};

export function PlayersListings() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(defaultParams);

  if ([...searchParams.entries()].length === 0) {
    setSearchParams(defaultParams);
  }

  const [loadingCsv, setLoadingCsv] = useState(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const sorting = [
    {
      id: searchParams.get("sort") || "name",
      desc: searchParams.get("dir") === "desc",
    },
  ];

  const pagination = {
    pageIndex: parseInt(searchParams.get("page") || "1") - 1,
    pageSize: parseInt(searchParams.get("pageSize") || "20"),
  };

  const visibleColumns =
    searchParams.get("visible")?.split(",") || defaultVisibleColumns;
  const columnVisibility = columns.reduce<VisibilityState>((acc, column) => {
    acc[column.accessorKey] = visibleColumns.includes(column.accessorKey);
    return acc;
  }, {});

  const params = Object.fromEntries(searchParams);
  delete params.page;
  delete params.pageSize;
  delete params.sort;
  delete params.dir;
  delete params.visible;

  const { response, loading, error } = useAsync(playersLoader, {
    offset: pagination.pageIndex * pagination.pageSize,
    limit: pagination.pageSize,
    sortColumn: sorting[0].id,
    sortDir: sorting[0].desc ? "DESC" : "ASC",
    ...params,
  });

  const handleSelectPlayer = (id: number | null) => {
    if (!id) return;
    navigate(`${id}`);
  };

  const updateSearchParams = (params = {}) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      ...params,
    });
  };

  const numFilters = [...searchParams.keys()].filter(
    (key) => !["page", "pageSize", "sort", "dir", "visible"].includes(key)
  ).length;

  const getFilteredCsvPlayers = async () => {
    try {
      setLoadingCsv(true);
      const playersResponse = await apolloApi.getPlayers({
        offset: 0,
        limit: response?.count ?? 0,
        ...params,
      });

      toast(ToastType.SUCCESS, "CSV Downloaded");
      return playersResponse.players;
    } catch (error) {
      toast(ToastType.ERROR, "Unable to download CSV");
      return [];
    } finally {
      setLoadingCsv(false);
    }
  };

  const filteredCsvData = async () => {
    const data = await getFilteredCsvPlayers();

    return (
      data.map((player) => {
        const csvRow: Record<string, string | number | null | undefined> = {};

        columns.forEach((c) => {
          const key = c.accessorKey;

          if (c.csvFn) {
            csvRow[key] = c.csvFn(player);
          } else {
            const value = player[key as keyof PlayerRow];
            csvRow[key] =
              typeof value === "object" ? JSON.stringify(value) : value;
          }
        });

        return visibleColumns.reduce(
          (
            filteredObj: Record<string, string | number | null | undefined>,
            key
          ) => {
            filteredObj[key] = csvRow[key];
            return filteredObj;
          },
          {} as Record<string, string | number | null | undefined>
        );
      }) ?? []
    );
  };

  const csvHeaders: { id: string; displayName: string }[] = [];
  columns.forEach((column) => {
    visibleColumns.forEach((visibleColumn) => {
      if (visibleColumn === column.accessorKey) {
        csvHeaders.push({
          id: column.accessorKey,
          displayName: column.header,
        });
      }
    });
  });

  if (error) {
    throw new SunsApiError("Unable to load players.", {
      cause: error,
    });
  }

  return (
    <Page title="Players" breadcrumbs={false}>
      <Flex direction="down" gap="md" className="mt-4 lg:mt-2">
        <Sheet open={filterDrawerOpen} onOpenChange={setFilterDrawerOpen}>
          <PlayerFilters
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            onSubmit={() => {
              setFilterDrawerOpen(false);
            }}
          />
        </Sheet>
        <Flex direction="right" gap="md" justify="between">
          <Flex gap="md">
            <Button variant="outline" onClick={() => setFilterDrawerOpen(true)}>
              <SlidersVerticalIcon size={24} className="pr-2" /> Filters
              {numFilters > 0 && <Badge className="ml-2">{numFilters}</Badge>}
            </Button>
            <ColumnFilters
              columns={columns}
              columnVisibility={columnVisibility}
              sections={sections}
              onChange={(column, checked) => {
                const newColumnVisibility = {
                  ...columnVisibility,
                  [column]: !checked,
                };

                updateSearchParams({
                  visible: Object.keys(newColumnVisibility)
                    .filter((key) => Boolean(newColumnVisibility[key]))
                    .join(","),
                });
              }}
            />
          </Flex>
          <CsvDownloader
            className="max-sm:hidden"
            datas={filteredCsvData}
            columns={csvHeaders}
            filename="players.csv"
            separator=";"
            meta
          >
            <Button variant="outline" disabled={loadingCsv || loading}>
              {loadingCsv ? (
                <Flex direction="right" gap="sm" align="center">
                  <Text size="sm">Downloading...</Text>
                  <LoaderCircle size={18} className="animate-spin" />
                </Flex>
              ) : (
                <Text size="sm">Download as CSV</Text>
              )}
            </Button>
          </CsvDownloader>
        </Flex>
        <DataTable
          loading={loading}
          columns={columns}
          data={response?.players || []}
          count={response?.count || 0}
          pagination={pagination}
          setPagination={(setPagination) => {
            const newPagination =
              typeof setPagination === "function"
                ? setPagination(pagination)
                : setPagination;

            updateSearchParams({
              page: `${newPagination.pageIndex + 1}`,
              pageSize: `${newPagination.pageSize}`,
            });
          }}
          sorting={sorting}
          setSorting={(setSorting) => {
            const newSort =
              typeof setSorting === "function"
                ? setSorting(sorting)
                : setSorting;

            const sort = newSort[0];

            updateSearchParams({
              sort: sort.id,
              dir: sort.desc ? "desc" : "asc",
            });
          }}
          columnVisibility={columnVisibility}
          setColumnVisibility={(setColumnVisibility) => {
            const newColumnVisibility =
              typeof setColumnVisibility === "function"
                ? setColumnVisibility(columnVisibility)
                : setColumnVisibility;

            updateSearchParams({
              visible: Object.keys(newColumnVisibility)
                .filter((key) => Boolean(newColumnVisibility[key]))
                .join(","),
            });
          }}
          onRowClicked={(row) => handleSelectPlayer(row.original.id)}
        />
      </Flex>
    </Page>
  );
}
