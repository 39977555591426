import { apolloApi } from "@/shared/api";
import { useAsync } from "@/shared/hooks";

export function useLeagues() {
  const { loading, response, error, refresh } = useAsync(async () => {
    const data = await apolloApi.getLeagues({});
    return data.leagues;
  });

  return { leagues: response, loading, error, refresh };
}
