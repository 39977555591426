import "./error.scss";
import { NavLink } from "react-router-dom";

export function PageNotFound() {
  return (
    <div className="error-page" id="error-page">
      <div className="error-block">
        <h1>404</h1>
        <h5>Page Not Found</h5>
        <p>The page you're looking for does not seem to exist.</p>
        <NavLink to="/" className="btn btn-primary">
          Go to Home
        </NavLink>
      </div>
    </div>
  );
}
