import {
  ApolloGradeValueLabels,
  ReportScoreDescriptions,
  SkillScoreSections,
} from "@/pages/reports/reports-const";
import {
  PlayerMetadataScoreRow,
  PlayerRow,
  ReportScore,
} from "@suns/api/generated-client/apollo";
import { ApolloBadge, Flex, Grid, Text } from "@suns/design-system";
import { ChartContainer } from "@suns/design-system/src/components/Chart/Chart";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  XAxis,
  YAxis,
} from "recharts";
import { PlayerMetadataPositionLabels } from "../players-const";
import { colors } from "@/shared/utils/colors";
import { BarChart2Icon, UserIcon } from "@suns/design-system/icons";
import { PlayerMetadataRoleLabels } from "../players-const";
import { theme } from "@/shared/theme";

export function PlayerScouting({ player }: { player: PlayerRow }) {
  const hasMetadata = player.metadata?.[0] != null;
  if (!hasMetadata) {
    return (
      <Text heading muted size="lg" className="py-4 text-center">
        No analytic data available.
      </Text>
    );
  }

  const hybridScores = player.metadata[0].hybridScores;

  return (
    <Flex direction="down" gap="lg">
      {hybridScores && hybridScores?.length > 0 && (
        <>
          <ScoutingGrades player={player} />
          <Flex gap="lg" className="flex-col md:flex-row">
            <OffenseSection scores={hybridScores} />
            <Flex direction="down" gap="md" className="w-full">
              <DeffenseSection scores={hybridScores!} />
              <CanDefendSection scores={hybridScores!} />
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
}

function ScoutingGrades({ player }: { player: PlayerRow }) {
  const playerMetadata = {
    Grade: [
      ApolloGradeValueLabels[
        player.metadata[0]?.scoutGradeCurrent
          ?.value as keyof typeof ApolloGradeValueLabels
      ] ?? "--",
      ApolloGradeValueLabels[
        player.metadata[0]?.analyticGradeCurrent
          ?.value as keyof typeof ApolloGradeValueLabels
      ] ?? "--",
    ],
    "Remaining Capacity": [
      ApolloGradeValueLabels[
        player.metadata[0]?.scoutGradeRemainingCapacity
          ?.value as keyof typeof ApolloGradeValueLabels
      ] ?? "--",
      "--",
    ],
    Position: [
      PlayerMetadataPositionLabels[
        player.metadata[0]
          ?.scoutPosition as keyof typeof PlayerMetadataPositionLabels
      ] ?? "--",
      "--",
    ],
    Role: [
      PlayerMetadataRoleLabels[
        player.metadata[0]?.scoutRole as keyof typeof PlayerMetadataRoleLabels
      ] ?? "--",
      "--",
    ],
  };

  return (
    <>
      <Flex gap="lg" className="mb-1 max-sm:hidden">
        {Object.entries(playerMetadata).map(([key, value]) => {
          const [scout, analytics] = value;
          return (
            <Flex key={key} direction="down" gap="xs">
              <Text size="xs" muted heading>
                {key}
              </Text>
              <Flex direction="right" gap="xs">
                <ApolloBadge
                  size={22}
                  color={theme.colors.teal.DEFAULT}
                  icon={UserIcon}
                />
                <Text size="md" heading>
                  {scout}
                </Text>
              </Flex>
              <Flex direction="right" gap="xs">
                <ApolloBadge
                  size={22}
                  color={theme.colors.magenta.DEFAULT}
                  icon={BarChart2Icon}
                />
                <Text size="md" heading>
                  {analytics}
                </Text>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
      <Flex direction="right" gap="md" className="my-2 w-full pl-5 sm:hidden">
        <Flex direction="down" className="w-full">
          <Grid columns="2" gap="md">
            {Object.entries(playerMetadata).map(([key, value]) => {
              const [scout, analytics] = value;
              return (
                <Flex key={key} direction="down" gap="xs">
                  <Text size="sm" muted heading>
                    {key}
                  </Text>

                  <Flex direction="right" gap="xs">
                    <ApolloBadge
                      size={22}
                      color={theme.colors.teal.DEFAULT}
                      icon={UserIcon}
                    />
                    <Text size="md" heading>
                      {scout}
                    </Text>
                  </Flex>

                  <Flex direction="right" gap="xs">
                    <ApolloBadge
                      size={22}
                      color={theme.colors.magenta.DEFAULT}
                      icon={BarChart2Icon}
                    />
                    <Text size="md" heading>
                      {analytics}
                    </Text>
                  </Flex>
                </Flex>
              );
            })}
          </Grid>
        </Flex>
      </Flex>
    </>
  );
}

function OffenseSection({ scores }: { scores: PlayerMetadataScoreRow[] }) {
  const offenseScores = SkillScoreSections.OFFENSE.map(
    (section) => section.scores
  ).flat();
  const chartData = offenseScores.map((score) => {
    const playerScore = scores.find((s) => s.key === score);
    return {
      key: score,
      value: playerScore?.value ?? 0,
      label:
        ReportScoreDescriptions[score].fullLabel ||
        ReportScoreDescriptions[score].label,
    };
  });

  return (
    <Flex direction="down" gap="md" className="w-full">
      <Text heading muted size="md">
        Offense
      </Text>
      <ChartContainer config={{}} className="h-[250px] w-full">
        <BarChart data={chartData} barSize={22} layout="vertical">
          <CartesianGrid vertical={true} horizontal={false} stroke="#ddd" />
          <XAxis
            dataKey="value"
            type="number"
            hide={true}
            domain={[0, 5]}
            ticks={[1, 2, 3, 4, 5]}
          />
          <YAxis
            dataKey="label"
            width={150}
            type="category"
            axisLine={false}
            tickLine={false}
          />
          <Bar dataKey="value" fill={colors.xihuitl} radius={4}>
            <LabelList
              dataKey="value"
              position="insideLeft"
              className="fill-[white] font-bold"
              offset={8}
            />
          </Bar>
        </BarChart>
      </ChartContainer>
    </Flex>
  );
}

function DeffenseSection({ scores }: { scores: PlayerMetadataScoreRow[] }) {
  const defenseScores = SkillScoreSections.DEFENSE.map(
    (section) => section.scores
  ).flat();
  const chartData = defenseScores.map((score) => {
    const playerScore = scores.find((s) => s.key === score);
    return {
      key: score,
      value: playerScore?.value ?? 0,
      label:
        ReportScoreDescriptions[score].fullLabel ||
        ReportScoreDescriptions[score].label,
    };
  });
  return (
    <Flex direction="down" gap="md" className="w-full">
      <Text heading muted size="md">
        Defense
      </Text>
      <ChartContainer config={{}} className="h-[170px] w-full">
        <BarChart data={chartData} barSize={22} layout="vertical">
          <CartesianGrid vertical={true} horizontal={false} stroke="#ddd" />
          <XAxis
            dataKey="value"
            type="number"
            hide={true}
            domain={[0, 5]}
            ticks={[1, 2, 3, 4, 5]}
          />
          <YAxis
            dataKey="label"
            width={150}
            type="category"
            axisLine={false}
            tickLine={false}
          />
          <Bar dataKey="value" fill={colors.xihuitl} radius={4}>
            <LabelList
              dataKey="value"
              position="insideLeft"
              className="fill-[white] font-bold"
              offset={8}
            />
          </Bar>
        </BarChart>
      </ChartContainer>
    </Flex>
  );
}

function CanDefendSection({ scores }: { scores: PlayerMetadataScoreRow[] }) {
  const chartData = [
    ReportScore.key.DEFEND_POINT_GUARD,
    ReportScore.key.DEFEND_GUARD,
    ReportScore.key.DEFEND_WING,
    ReportScore.key.DEFEND_FORWARD,
    ReportScore.key.DEFEND_BIG,
  ].map((score) => {
    const playerScore = scores.find((s) => s.key === score);
    let label = null;
    switch (score) {
      case ReportScore.key.DEFEND_POINT_GUARD:
        label = "Point Guard";
        break;
      case ReportScore.key.DEFEND_GUARD:
        label = "Guard";
        break;
      case ReportScore.key.DEFEND_WING:
        label = "Wing";
        break;
      case ReportScore.key.DEFEND_FORWARD:
        label = "Forward";
        break;
      case ReportScore.key.DEFEND_BIG:
        label = "Big";
        break;
    }

    return {
      key: score,
      value: playerScore?.value ?? 0,
      displayValue: `${(Number(playerScore?.value) / 2) * 100}%`,
      label,
    };
  });
  return (
    <Flex direction="down" gap="md" className="w-full">
      <Text heading muted size="md">
        Can Defend
      </Text>
      <ChartContainer config={{}} className="h-[170px] w-full">
        <BarChart data={chartData} barSize={22} layout="vertical">
          <CartesianGrid vertical={true} horizontal={false} stroke="#ddd" />
          <XAxis
            dataKey="value"
            type="number"
            domain={[0, 2]}
            ticks={[0, 1, 2]}
            axisLine={false}
            tickLine={false}
            tickFormatter={(value) => {
              switch (value) {
                case 0:
                  return "None";
                case 1:
                  return "Some";
                case 2:
                  return "All";
              }
              return value;
            }}
          />
          <YAxis
            dataKey="label"
            width={150}
            type="category"
            axisLine={false}
            tickLine={false}
          />
          <Bar
            dataKey="value"
            fill={colors.xihuitl}
            radius={4}
            minPointSize={36}
          >
            <LabelList
              dataKey="displayValue"
              position="insideLeft"
              className="fill-[white] font-bold"
              offset={8}
            />
          </Bar>
        </BarChart>
      </ChartContainer>
    </Flex>
  );
}
