import { apolloApi } from "@/shared/api";
import { LeagueId } from "@suns/api";
import { useAsync } from "./useAsync";

export type PlayerCacheItem = {
  type: "player";
  key: string;
  id: number;
  fullName: string;
  display: string;
  teamCode: string;
  leagueId: LeagueId;
};

type PlayerMap = Map<string, PlayerCacheItem>;
let PLAYER_MAP: PlayerMap | undefined;
let PLAYER_CACHE_PROMISE: Promise<PlayerMap> | null = null;

export function usePlayerMap() {
  return useAsync(async () => {
    if (PLAYER_MAP) {
      return PLAYER_MAP;
    }

    if (PLAYER_CACHE_PROMISE) {
      return PLAYER_CACHE_PROMISE;
    }

    PLAYER_CACHE_PROMISE = apolloApi
      .getPlayerCache({})
      .then((response) => {
        PLAYER_MAP = new Map<string, PlayerCacheItem>();
        response.cache.forEach((player) =>
          PLAYER_MAP!.set(`player-${player.id}`, {
            type: "player" as const,
            key: `player-${player.id}`,
            id: player.id,
            fullName: `${player.firstName} ${player.lastName}`,
            display: `${player.lastName}, ${player.firstName}`,
            teamCode: player.teamCode,
            leagueId: player.leagueId,
          })
        );

        return PLAYER_MAP;
      })
      .finally(() => {
        PLAYER_CACHE_PROMISE = null;
      });

    return PLAYER_CACHE_PROMISE;
  });
}
