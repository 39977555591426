import {
  DropdownMenu,
  DropdownMenuTrigger,
  Button,
  Flex,
  Separator,
  Badge,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuSubTrigger,
  DropdownMenuSub,
  DropdownMenuPortal,
  DropdownMenuSubContent,
  Drawer,
  DrawerTrigger,
  DrawerContent,
  DrawerHeader,
  Text,
  DrawerTitle,
  ScrollArea,
} from "@suns/design-system";
import { CheckIcon, PlusCircleIcon } from "@suns/design-system/icons";
import { ColumnDef, VisibilityState } from "@tanstack/react-table";
import { useEffect, useState } from "react";

export type Column<T> = ColumnDef<T> & {
  accessorKey: string;
  header: string;
  sortingFn?: "alphanumeric" | "text";
  csvFn?: (row: T) => string | number | null;
};

export interface ColumnSection {
  grouping: string;
  accessorKeys: string[];
}

export function ColumnFilters<T>({
  columns,
  columnVisibility,
  sections,
  onChange,
}: {
  columns: Column<T>[];
  columnVisibility: VisibilityState;
  sections: ColumnSection[];
  onChange: (column: string, checked: boolean) => void;
}) {
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const hidden = Object.keys(columnVisibility).filter(
      (column) => columnVisibility[column] === false
    );
    setHiddenColumns(hidden);
  }, [columnVisibility]);

  return (
    <Flex>
      <Flex className="hidden md:flex">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="dashed" className="bg-white">
              <Flex direction="right" gap="sm" align="center">
                <PlusCircleIcon size={18} />
                Columns
                <Separator orientation="vertical" className="mx-2 h-4" />
                <Badge className="rounded-sm">
                  {
                    Object.values(columns).filter(
                      (column) =>
                        column.enableHiding !== false &&
                        !hiddenColumns.includes(column.accessorKey)
                    ).length
                  }{" "}
                  Selected
                </Badge>
              </Flex>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-60">
            {sections.map((section) => (
              <DropdownMenuSub key={section.grouping}>
                <DropdownMenuSubTrigger>
                  {section.grouping}
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    {section.accessorKeys.map((accessorKey) => {
                      const tableColumn = columns.find(
                        (c) => c.accessorKey === accessorKey
                      );

                      if (!tableColumn) {
                        return null;
                      }

                      return (
                        <DropdownMenuCheckboxItem
                          key={accessorKey}
                          checked={!hiddenColumns.includes(accessorKey)}
                          onSelect={(e) => {
                            const checked = hiddenColumns.includes(accessorKey);
                            onChange(accessorKey, !checked);
                            e.preventDefault();
                          }}
                        >
                          {tableColumn.header}
                        </DropdownMenuCheckboxItem>
                      );
                    })}
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </Flex>

      <Flex className="md:hidden">
        <Drawer open={drawerOpen} onOpenChange={setDrawerOpen}>
          <DrawerTrigger asChild>
            <Button variant="dashed" className="bg-white">
              <Flex direction="right" gap="sm" align="center">
                <PlusCircleIcon size={18} />
                Columns
                <Separator orientation="vertical" className="mx-2 h-4" />
                <Badge className="rounded-sm">
                  {
                    Object.values(columns).filter(
                      (column) =>
                        column.enableHiding !== false &&
                        !hiddenColumns.includes(column.accessorKey)
                    ).length
                  }{" "}
                  Selected
                </Badge>
              </Flex>
            </Button>
          </DrawerTrigger>
          <DrawerContent className="h-[60%]">
            <DrawerHeader>
              <DrawerTitle>Columns</DrawerTitle>
            </DrawerHeader>
            <ScrollArea>
              <Flex direction="down" gap="md" className="px-8">
                {sections.map((section) => (
                  <Flex direction="down" gap="md" key={section.grouping}>
                    <Text size="md" heading>
                      {section.grouping}
                    </Text>
                    <Separator />
                    {section.accessorKeys.map((accessorKey) => {
                      const tableColumn = columns.find(
                        (c) => c.accessorKey === accessorKey
                      );

                      if (!tableColumn) {
                        return null;
                      }

                      return (
                        <div key={accessorKey}>
                          <Flex
                            className="pl-2"
                            gap="sm"
                            direction="right"
                            align="center"
                            onClick={() => {
                              const checked =
                                hiddenColumns.includes(accessorKey);
                              onChange(accessorKey, !checked);
                            }}
                          >
                            {!hiddenColumns.includes(accessorKey) ? (
                              <CheckIcon size={22} />
                            ) : (
                              <Flex className="min-w-5" />
                            )}
                            <Text size="lg">{tableColumn.header}</Text>
                          </Flex>
                          <Separator />
                        </div>
                      );
                    })}
                  </Flex>
                ))}
              </Flex>
            </ScrollArea>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Flex>
  );
}
