import { apolloApi, queryPlayers } from "@/shared/api";
import { ReportQueryParams } from "@suns/api/generated-client/apollo";
import { parseNbaStats, percent } from "@/shared/utils/helper-functions";
import { PlayerGameStatsParams } from "@suns/api/src/suns-api";
import { PlayerQueryParams } from "@suns/api/generated-client/apollo";
import { MeasureType, SeasonType } from "@suns/api/src/params";
import { formatFixed } from "@/shared/utils/helper-functions";
import dayjs from "dayjs";
import { gLeagueTeams, teams } from "@/shared/utils/teams";
import { QueryParams } from "@suns/api";
import { perMode } from "@suns/api/generated-client/querytool";
import {
  playerGameMeasureTypeConfigs,
  seasonMeasureTypeConfigs,
} from "./playerStatConfig";

export async function viewPlayerLoader({ playerId }: { playerId: number }) {
  const { player } = await apolloApi.getPlayer(playerId);
  return { player };
}

export async function playersLoader(params: PlayerQueryParams) {
  const response = await apolloApi.getPlayers(params);

  return response;
}

export async function playersReportsLoader({
  status,
  offset = 0,
  limit = 20,
  playerId,
  type,
  authorUsername,
}: {
  status: ReportQueryParams.status.PUBLISHED;
  offset: number;
  limit: number;
  playerId?: number;
  type?: ReportQueryParams.type;
  authorUsername?: string[];
}) {
  const response = await apolloApi.getReports({
    offset,
    limit,
    status,
    playerIds: playerId ? [playerId] : undefined,
    type,
    authorUsername,
  });

  return response;
}

export async function playerSeasonStatsLoader(
  queryParams: QueryParams<"season">
) {
  const seasonTypes = Array.isArray(queryParams.seasonType)
    ? queryParams.seasonType.join(",")
    : queryParams.seasonType;

  const response = await queryPlayers({
    ...queryParams,
    seasonType: seasonTypes as SeasonType,
  });

  const stats = response?.data?.players?.map((player) => player.stats);
  if (!stats) return;

  const otherColumns = {
    season: response?.data?.players?.map((stat) => stat?.seasonYear) ?? null,
    teamName:
      response?.data?.players?.map((stat) => stat?.teamAbbreviation) ?? null,
  };

  const mappedStats = await Promise.all(
    stats.map((stat, index) => {
      if (!stat)
        return parseNbaStats(
          {},
          queryParams.measureType ?? "",
          "season",
          seasonMeasureTypeConfigs((queryParams.perMode as perMode) ?? ""),
          {},
          {
            season: otherColumns.season?.[index] ?? null,
            teamName: otherColumns.teamName?.[index] ?? null,
          }
        );

      const processedStat = Object.entries(stat).reduce(
        (acc, [key, value]) => {
          acc[key.toLowerCase()] = value;
          return acc;
        },
        {} as Record<string, number | string | null>
      );

      return parseNbaStats(
        processedStat,
        queryParams.measureType ?? "",
        "season",
        seasonMeasureTypeConfigs((queryParams.perMode as perMode) ?? ""),
        {},
        {
          season: otherColumns.season?.[index] ?? null,
          teamName: otherColumns.teamName?.[index] ?? null,
        }
      );
    })
  );

  const multiTeamSeasons = mappedStats.reduce(
    (acc, curr, i) => {
      const season = curr?.stats[0];
      if (i > 0 && season === mappedStats[i - 1]?.stats[0]) {
        if (!acc[season as string]) {
          acc[season as string] = [];
        }
        if (!acc[season as string].includes(curr)) {
          acc[season as string].push(curr);
        }
        if (!acc[season as string].includes(mappedStats[i - 1])) {
          acc[season as string].push(mappedStats[i - 1]);
        }
      }
      return acc;
    },
    {} as Record<string, (typeof mappedStats)[number][]>
  );

  const multiTeamStats = Object.values(multiTeamSeasons).map((stat) => {
    if (!stat.length) return null;
    const sum = new Array(stat[0].stats.length - 2).fill(0);
    let totalGamesPlayed = 0;

    stat.forEach((stats) => {
      const gamesPlayed = Number(stats.stats[2]);
      totalGamesPlayed += gamesPlayed;

      stats.stats.slice(2).forEach((value, i) => {
        const numericVal =
          typeof value === "string" && value.includes("%")
            ? parseFloat(value.replace("%", ""))
            : parseFloat(value as string);

        sum[i] += numericVal * gamesPlayed;
      });
    });

    const weightedAvg = sum.map((total) => {
      if (totalGamesPlayed === 0) return "0.00";
      return (total / totalGamesPlayed).toFixed(1);
    });

    const formattedStats = weightedAvg.slice(1).map((statVal, i) => {
      const statHeader = stat[0].statsHeaders?.[i + 3];
      const isPercentage = statHeader?.includes("%");
      return isPercentage
        ? percent(Number(statVal))
        : formatFixed(Number(statVal));
    });

    const stats = [
      stat[0].stats[0],
      "TOTAL",
      totalGamesPlayed.toString(),
      ...formattedStats,
    ];

    return { stats, statsHeaders: stat[0].statsHeaders };
  });

  const careerStats = mappedStats.reduce(
    (acc: { stats: number[]; totalGames: number }, stat) => {
      const gamesPlayed = Number(stat.stats[2]);
      acc.totalGames += !isNaN(gamesPlayed) ? gamesPlayed : 0;

      stat.stats.slice(2).forEach((value, i) => {
        const numValue = value?.toString()?.endsWith("%")
          ? Number(value?.toString()?.replace("%", ""))
          : Number(value);

        acc.stats[i] =
          (acc.stats[i] || 0) + (!isNaN(numValue) ? numValue * gamesPlayed : 0);
      });
      return acc;
    },
    { stats: [], totalGames: 0 }
  );

  if (careerStats.totalGames == 0) return mappedStats;
  const careerAverages =
    careerStats &&
    careerStats.stats.splice(1).map((statsTotal, i) => {
      const average =
        careerStats.totalGames > 0 ? statsTotal / careerStats.totalGames : 0;

      const statHeader = mappedStats[0].statsHeaders[i + 3];

      const isPercentage = statHeader.includes("%");
      return isPercentage ? percent(average) : formatFixed(average);
    });

  const careerStatRow = {
    stats: [
      "Career",
      "",
      careerStats.totalGames.toString(),
      ...careerAverages.map((value) => value.toString()),
    ],
    statsHeaders: {},
  };

  const sortedStats = [...mappedStats, ...multiTeamStats];

  sortedStats.sort((a, b) => {
    const seasonA = String(a?.stats[0] ?? "");
    const seasonB = String(b?.stats[0] ?? "");
    const teamA = String(a?.stats[1] ?? "");
    const teamB = String(b?.stats[1] ?? "");

    const seasonCompare = seasonB.localeCompare(seasonA);
    if (seasonCompare !== 0) return seasonCompare;

    if (teamA === "TOTAL" && teamB !== "TOTAL") return -1;
    if (teamA !== "TOTAL" && teamB === "TOTAL") return 1;

    return 0;
  });

  return [...sortedStats, careerStatRow];
}

export async function playerGameStatsLoader({
  leagueId,
  seasonYear,
  seasonType,
  playerId,
  measureType,
  playerLastNGames,
}: PlayerGameStatsParams) {
  const response = await queryPlayers({
    scope: "game",
    leagueId: leagueId,
    seasonYear,
    seasonType: seasonType as SeasonType,
    grouping: "None",
    teamGrouping: "N",
    playerId: playerId ?? undefined,
    measureType: measureType as MeasureType,
    playerLastNGames: playerLastNGames ? playerLastNGames : undefined,
  });

  const playerGameStats = response?.data?.players?.map(
    (player) => player.stats
  );
  if (playerGameStats) {
    playerGameStats.sort((a, b) => {
      const dateA = new Date(a?.gameDate ?? "");
      const dateB = new Date(b?.gameDate ?? "");
      return dateB.getTime() - dateA.getTime();
    });
  }

  if (!playerGameStats) return { stats: [], statsHeaders: [] };

  const otherColumns = {
    gameDate: response?.data?.players?.map((stat) => stat?.gameDate),
    team: response?.data?.players?.[0]?.teamAbbreviation,
    opponent: response?.data?.players?.map((stat) => stat?.opponentName),
    outcome: response?.data?.players?.map((stat) => stat?.gameOutcome),
  };

  const teamAbbrs = otherColumns.opponent?.map(
    (opponent) =>
      [...teams, ...gLeagueTeams].find(
        (team) =>
          opponent &&
          opponent.toLowerCase() === team.TEAM_FULL_NAME.toLowerCase()
      )?.TEAM_ABBR
  );

  const newStats = playerGameStats.map((stat, index) => {
    const numericStat = Object.fromEntries(
      Object.entries(stat ?? {})
        .filter(
          ([, value]) => typeof value === "number" || typeof value === "string"
        )
        .map(([key, value]) => [key.toLowerCase(), value])
    );

    return {
      ...numericStat,
      matchUp: `${dayjs(otherColumns.gameDate?.[index]).format("MMM DD, YYYY")} - ${
        teamAbbrs?.[index] ?? null
      }`,
      outcome: otherColumns.outcome?.[index] ?? null,
    };
  });

  const mappedStats = await Promise.all(
    newStats.map((stat) =>
      parseNbaStats(
        stat,
        measureType,
        "game",
        {},
        playerGameMeasureTypeConfigs,
        {}
      )
    )
  );

  return mappedStats;
}
