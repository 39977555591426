import { parseNbaStats } from "@/shared/utils/helper-functions";
import {
  apolloApi,
  getTeamStats,
  queryPlayers,
  queryTeams,
} from "@/shared/api";
import {
  PlayerSeasonStatsParams,
  TeamGameStatsParams,
  TeamSeasonStatsParams,
} from "@suns/api/src/suns-api";
import {
  teamGameMeasureTypeConfigs,
  teamRosterMeasureTypeConfigs,
  teamSeasonMeasureTypeConfigs,
} from "./team-stats-config";
import { NbaLeagueId, MeasureType, PerMode } from "@suns/api/src/params";
import { SeasonType } from "@suns/api/src/params";
import { gLeagueTeams } from "@/shared/utils/teams";
import { teams } from "@/shared/utils/teams";
import dayjs from "dayjs";
import { TeamQueryParams } from "@suns/api/generated-client/apollo";

export async function teamsLoader(params: TeamQueryParams) {
  const response = await apolloApi.getTeams(params);

  return response;
}

export async function teamSeasonStatsLoader({
  leagueId,
  perMode,
  season,
  seasonType,
  teamId,
  measureType,
}: TeamSeasonStatsParams) {
  if (!teamId) return { stats: [], statsHeaders: [] };
  const response = await getTeamStats({
    leagueId,
    perMode,
    season,
    seasonType,
    teamId,
    measureType,
  });

  const teamSeasonStats = response?.teams?.map((team) => team.stats);
  if (!teamSeasonStats) return;

  teamSeasonStats.sort((a, b) => {
    const seasonA = a?.season ?? "";
    const seasonB = b?.season ?? "";
    return seasonB.localeCompare(seasonA);
  });

  const mappedStats = await Promise.all(
    teamSeasonStats.map((stat) =>
      parseNbaStats(
        stat ?? {},
        measureType,
        "season",
        teamSeasonMeasureTypeConfigs,
        {}
      )
    )
  );

  return mappedStats;
}

export async function teamsGameStatsLoader({
  leagueId,
  seasonYear,
  seasonType,
  teamId,
  measureType,
  teamLastNGames,
}: TeamGameStatsParams) {
  const response = await queryTeams({
    scope: "game",
    leagueId: leagueId,
    seasonYear,
    seasonType: seasonType as SeasonType,
    grouping: "None",
    teamGrouping: "N",
    teamId: teamId ?? undefined,
    measureType: measureType as MeasureType,
    teamLastNGames: teamLastNGames ? teamLastNGames : undefined,
  });

  const teamGames = response?.data?.teams;
  if (!teamGames) return;

  teamGames.sort((a, b) => {
    const dateA = new Date(a?.gameDate ?? "");
    const dateB = new Date(b?.gameDate ?? "");
    return dateB.getTime() - dateA.getTime();
  });

  if (!teamGames.length) return;

  const teamGameStats = teamGames?.map((team) => team.stats);

  const otherColumns = {
    gameDate: response?.data?.teams?.map((stat) => stat?.gameDate),
    team: response?.data?.teams?.[0]?.teamAbbreviation,
    opponent: response?.data?.teams?.map((stat) => stat?.opponentName),
    outcome: response?.data?.teams?.map((stat) => stat?.gameOutcome),
  };

  const teamAbbrs = otherColumns.opponent?.map(
    (opponent) =>
      [...teams, ...gLeagueTeams].find(
        (team) =>
          opponent &&
          opponent.toLowerCase() === team.TEAM_FULL_NAME.toLowerCase()
      )?.TEAM_ABBR
  );

  const newStats = teamGameStats.map((stat, index) => {
    const numericStat = Object.fromEntries(
      Object.entries(stat ?? {})
        .filter(
          ([, value]) => typeof value === "number" || typeof value === "string"
        )
        .map(([key, value]) => [key.toLowerCase(), value])
    );

    return {
      ...numericStat,
      matchUp: `${dayjs(otherColumns.gameDate?.[index]).format("MMM DD, YYYY")} - ${
        teamAbbrs?.[index] ?? null
      }`,
      outcome: otherColumns.outcome?.[index] ?? null,
    };
  });

  const mappedStats = await Promise.all(
    newStats.map((stat) =>
      parseNbaStats(stat, measureType, "game", {}, teamGameMeasureTypeConfigs)
    )
  );

  return mappedStats;
}

export async function teamRosterStatsLoader({
  leagueId,
  season,
  seasonType,
  teamId,
  measureType,
  perMode,
  activeRoster,
}: PlayerSeasonStatsParams) {
  const response = await queryPlayers({
    scope: "season",
    leagueId: leagueId as NbaLeagueId,
    seasonYear: season,
    seasonType: seasonType as SeasonType,
    grouping: "None",
    teamGrouping: "N",
    teamId: teamId ?? undefined,
    measureType: measureType as MeasureType,
    perMode: perMode as PerMode,
    activeRoster: activeRoster as 1 | 2,
  });

  const perModeSuffixes = {
    PerGame: "_pg",
    Per100: "_PER_100_POSS",
    PerPoss: "_PER_POSS",
    Per36: "_PER_36_MIN",
    Per48: "_PER_48_MIN",
    Totals: "",
  };

  const perModeKey = perModeSuffixes[perMode as keyof typeof perModeSuffixes];

  const rosterSeasonStats = response?.data?.players?.map(
    (player) => player.stats
  );
  if (!rosterSeasonStats) return;

  const otherColumns = {
    nameAbbr: response?.data?.players?.map((stat) => stat?.nameI),
  };

  const newStats = rosterSeasonStats.map((stat, index) => {
    const numericStat = Object.fromEntries(
      Object.entries(stat ?? {})
        .filter(
          ([, value]) => typeof value === "number" || typeof value === "string"
        )
        .map(([key, value]) => [key.toLowerCase(), value])
    );

    return {
      ...numericStat,
      nameAbbr: otherColumns.nameAbbr?.[index] ?? "--",
    };
  });

  if (!newStats) return;

  newStats.sort(
    (
      a: Record<string, string | number | null>,
      b: Record<string, string | number | null>
    ) => {
      const minA = Number(a?.[`min${perModeKey}`] ?? Number(a?.min) ?? 0);
      const minB = Number(b?.[`min${perModeKey}`] ?? Number(b?.min) ?? 0);
      return minB - minA;
    }
  );

  const mappedStats = await Promise.all(
    newStats.map((stat) =>
      parseNbaStats(
        stat,
        measureType,
        "season",
        teamRosterMeasureTypeConfigs(perMode),
        {}
      )
    )
  );

  return mappedStats;
}
