import { PlayerRow } from "@suns/api/generated-client/apollo";
import { Flex, Text } from "@suns/design-system";
import ApolloGradeChart from "./ApolloGradeChart";
import { PlayerApolloGradeValueChart } from "./PlayerApolloGradeValueChart";
import { PlayerAimChart } from "./PlayerAimChart";
import PlayerPpvChart from "./PlayerPpvChart";
import { PlayerPrvChart } from "./PlayerPrvChart";

export function PlayerAnalytics({ player }: { player: PlayerRow }) {
  const hasMetadata = player.metadata?.[0] != null;
  if (!hasMetadata) {
    return (
      <Text heading muted size="lg" className="py-4 text-center">
        No analytic data available.
      </Text>
    );
  }

  return (
    <Flex direction="down" gap="lg">
      <Flex direction="down" gap="sm">
        <Text heading muted size="md">
          Apollo Grade
        </Text>
        <ApolloGradeChart metadata={player.metadata[0]} />
      </Flex>
      <Flex gap="lg" className="flex-col md:flex-row">
        <PlayerApolloGradeValueChart playerMetadata={player.metadata} />
        <PlayerAimChart playerMetadata={player.metadata} />
      </Flex>
      <Flex gap="lg" className="flex-col md:flex-row">
        <PlayerPpvChart playerMetadata={player.metadata} />
        <PlayerPrvChart playerMetadata={player.metadata} />
      </Flex>
    </Flex>
  );
}
