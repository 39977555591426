import {
  ContractBonusRow,
  ContractProtectionRow,
  ContractRow,
  ContractSalaryRow,
} from "@suns/api/generated-client/apollo";
import { IntelTag } from "@suns/api/generated-client/apollo";

export const PlayerMetadataRoleLabels = {
  PLAYMAKER: "Playmaker (PM)",
  SHOOTER: "Shooter (SH)",
  PERIMETER_SCORER: "Perimeter Scorer (PS)",
  SLASHER_DRIVER: "Slasher/Driver (SD)",
  INTERIOR_FINISHER: "Interior Finisher (IF)",
  PERIMETER_DEFENDER: "Perimeter Defender (PD)",
  INTERIOR_DEFENDER: "Interior Defender (ID)",
};

export const PlayerMetadataPositionLabels = {
  POINT_GUARD: "Point Guard (PG)",
  GUARD: "Guard (G)",
  WING: "Wing (W)",
  FORWARD: "Forward (F)",
  BIG: "Big (B)",
};

export const PlayersIntelTagsLabels = {
  [IntelTag.BASKETBALL]: "Basketball",
  [IntelTag.BACKGROUND]: "Background",
  [IntelTag.CHARACTER]: "Character",
  [IntelTag.MEDICAL]: "Medical",
  [IntelTag.BODY]: "Body",
  [IntelTag.PSYCH]: "Psych",
  [IntelTag.COACHING]: "Coaching",
  [IntelTag.DRAFT]: "Draft",
  [IntelTag.NIL]: "NIL",
  [IntelTag.TRADE]: "Trade",
  [IntelTag.FRONT_OFFICE]: "Front Office",
  [IntelTag.FREE_AGENCY]: "Free Agency",
  [IntelTag.MINI_CAMP]: "Mini Camp",
  [IntelTag.CONTRACT]: "Contract",
};

export const ContractFlagLabels = {
  deathLimit30m: "$30M Death Limit",
  dpRookieScaleExtension: "DP Rookie Scale Extension",
  dpVeteranContract: "DP Veteran Contract",
  dpVeteranExtension: "DP Veteran Extension",
  exhibit1: "Exhibit 1",
  exhibit1a: "Exhibit 1A",
  exhibit1b: "Exhibit 1B",
  exhibit2: "Exhibit 2",
  exhibit3: "Exhibit 3",
  exhibit4: "Exhibit 4",
  exhibit5: "Exhibit 5",
  exhibit6: "Exhibit 6",
  exhibit7: "Exhibit 7",
  exhibit8: "Exhibit 8",
  exhibit9: "Exhibit 9",
  exhibit10: "Exhibit 10",
  fifthYearContract: "Fifth Year Contract",
  fifthYearExtension: "Fifth Year Extension",
  fullProtection: "Full Protection",
  hardshipContract: "Hardship Contract",
  incentiveBonus: "Incentive Bonus",
  internationalPlayerPayment: "International Player Payment",
  lifeInsuranceReimbursement: "Life Insurance Reimbursement",
  noTrade: "No Trade",
  par13bDel: "Par 13B Del",
};

export const ContractStatusLabels = {
  [ContractRow.status.ACT]: "Active",
  [ContractRow.status.APPR]: "Current",
  [ContractRow.status.CANC]: "Cancelled",
  [ContractRow.status.CEF]: "Final Review",
  [ContractRow.status.CER]: "League Review",
  [ContractRow.status.CES]: "Signed",
  [ContractRow.status.CETE]: "Team Edit",
  [ContractRow.status.COMP]: "Completed",
  [ContractRow.status.DEL]: "Deleted",
  [ContractRow.status.EXP]: "Expired",
  [ContractRow.status.FLPHY]: "Failed Physical",
  [ContractRow.status.FUTR]: "Future",
  [ContractRow.status.IN]: "Inactive",
  [ContractRow.status.MATCH]: "Matched",
  [ContractRow.status.NMTCH]: "Not Matched",
  [ContractRow.status.PRE]: "Preliminary",
  [ContractRow.status.REJ]: "Rejected",
  [ContractRow.status.REL]: "Released",
  [ContractRow.status.REPL]: "Replaced",
  [ContractRow.status.RESC]: "Rescinded",
  [ContractRow.status.SALCH]: "Salary Change",
  [ContractRow.status.SENT]: "Contract Sent",
  [ContractRow.status.SUB]: "Submitted",
  [ContractRow.status.TERM]: "Terminated",
  [ContractRow.status.UN]: "Unsaved",
  [ContractRow.status.VOID]: "Void",
  [ContractRow.status.WDRAW]: "Invalidated",
};

export const ContractTypeLabels = {
  [ContractRow.type._10D1]: "10-Day - 1st Contract",
  [ContractRow.type._10D2]: "10-Day - 2nd Contract",
  [ContractRow.type.QO]: "Qualifying Offer",
  [ContractRow.type.REGCT]: "Regular Contract",
  [ContractRow.type.REGOS]: "Regular Contract (Offer Sheet)",
  [ContractRow.type.REQSM]: "Required Tender (Summer Contract)",
  [ContractRow.type.REQTN]: "Required Tender",
  [ContractRow.type.RKSC]: "Rookie Scale Contract",
  [ContractRow.type.ROS]: "ROS Contract",
  [ContractRow.type.RSEX]: "Rookie Scale Extension",
  [ContractRow.type.SUMM]: "Summer Contract",
  [ContractRow.type.REGSM]: "Regular Contract (Converted Summer)",
  [ContractRow.type.VETEX]: "Veteran Extension",
  [ContractRow.type.MOS]: "Matched Offer Sheet",
  [ContractRow.type.OFFSH]: "Signed Offer Sheet",
  [ContractRow.type.W7D1]: "7-Day - 1st Contract",
  [ContractRow.type.W7D2]: "7-Day - 2nd Contract",
  [ContractRow.type.W7D3]: "7-Day - 3rd Contract",
  [ContractRow.type.WCEX]: "Contract Extension",
  [ContractRow.type.WCORE]: "Core Contract",
  [ContractRow.type.WHARD]: "ROS (Hardship)",
  [ContractRow.type.WMOS]: "Matched Offer Sheet",
  [ContractRow.type.WOFFS]: "Signed Offer Sheet",
  [ContractRow.type.WRGCT]: "Regular Contract",
  [ContractRow.type.WRGOS]: "Regular Contract (Offer Sheet)",
  [ContractRow.type.WRGTC]: "Regular Contract (Converted Training Camp)",
  [ContractRow.type.WRKSC]: "Rookie Scale Contract",
  [ContractRow.type.WRKTC]: "Rookie Scale Contract (TC)",
  [ContractRow.type.WROS]: "ROS Contract",
  [ContractRow.type.WRQTN]: "Required Tender",
  [ContractRow.type.WTRCC]: "Training Camp Contract",
  [ContractRow.type.TMREG]: "Regular",
  [ContractRow.type.TM10D]: "10 Day",
  [ContractRow.type.TMSUM]: "Summer",
  [ContractRow.type.TMRSE]: "Rookie Scale Extension",
  [ContractRow.type.TMVTE]: "Veteran Extension",
  [ContractRow.type.TMRSM]: "Regular Contract (Converted Summer)",
  [ContractRow.type._2WCT]: "Two-Way Contract",
  [ContractRow.type.REG2W]: "Regular Contract (Converted Two-Way)",
  [ContractRow.type.REGCV]: "Two-Way Contract (Converted NBA)",
  [ContractRow.type.TM2W]: "Two-Way Contract",
  [ContractRow.type.TM2RG]: "Regular Contract (Converted Two-Way)",
  [ContractRow.type.TM2RC]: "Two-Way Contract (Converted NBA)",
  [ContractRow.type.SUB]: "Substitute Contract",
  [ContractRow.type.WRGRS]: "Regular Contract (Converted Rookie Scale)",
};

export const ContractMinimumTypeLabels = {
  [ContractRow.minContract._3PLS]: "3+ Year Min",
  [ContractRow.minContract._1OR2]: "1 or 2 Year Min",
  [ContractRow.minContract.NO]: "No Min",
};

export const ContractMaximumTypeLabels = {
  [ContractRow.maxContract.FULL]: "Full Maximum",
  [ContractRow.maxContract._1ST]: "First Year Maximum",
  [ContractRow.maxContract.NO]: "No Maximum",
};

export const ContractSignedMethodLabels = {
  [ContractRow.signedMethod.BRD]: "Bird Exception",
  [ContractRow.signedMethod.MTP]: "Mid-Level Exception - TP",
  [ContractRow.signedMethod.MRM]: "Mid-Level Exception - Room",
  [ContractRow.signedMethod.BAE]: "Bi-Annual Exception",
  [ContractRow.signedMethod.DPE]: "Disabled Player Exception",
  [ContractRow.signedMethod.ROM]: "Room",
  [ContractRow.signedMethod.RFP]: "Room - FRP",
  [ContractRow.signedMethod.RMN]: "Room - Minimum",
  [ContractRow.signedMethod.DPKSE]: "DP Rookie Scale Extension",
  [ContractRow.signedMethod.KSE]: "Rookie Scale Extension",
  [ContractRow.signedMethod.VEE]: "Veteran Extension",
  [ContractRow.signedMethod.EBE]: "Early Bird Exception",
  [ContractRow.signedMethod.NBE]: "Non-Bird Exception",
  [ContractRow.signedMethod.MSEF]: "Min Salary Exception - Full",
  [ContractRow.signedMethod.MSERO]: "Min Salary Exception - ROS",
  [ContractRow.signedMethod.MSE10]: "Min Salary Exception - 10-Day",
  [ContractRow.signedMethod.KSX]: "Rookie Scale Exception",
  [ContractRow.signedMethod.MEP]: "Mid-Level Exception - Pre-2011",
  [ContractRow.signedMethod.MEN]: "Mid-Level Exception - Non-TP",
  [ContractRow.signedMethod.MINEX]: "Minimum Exception",
  [ContractRow.signedMethod.SM150]: "Simultaneous +150%",
  [ContractRow.signedMethod.SM125]: "Simultaneous +125%",
  [ContractRow.signedMethod.SM5M]: "Simultaneous +$5m",
  [ContractRow.signedMethod.TRDEX]: "TPE",
  [ContractRow.signedMethod.SIM]: "Simultaneous",
  [ContractRow.signedMethod.MINSL]: "Min Salary Exception",
  [ContractRow.signedMethod.CNTMD]: "Mid-Level Exception - Convertible",
  [ContractRow.signedMethod.NA]: "N/A",
  [ContractRow.signedMethod.SM175]: "Simultaneous +175%",
  [ContractRow.signedMethod._2RDPE]: "2nd Round Pick Exception",
  [ContractRow.signedMethod.NONE]: "None",
};

export const ContractFreeAgentDesignationLabels = {
  [ContractRow.freeAgentDesignation.QUAL]: "Qualifying",
  [ContractRow.freeAgentDesignation.ERQL]: "Early Qualifying",
  [ContractRow.freeAgentDesignation.NONQL]: "Non Qualifying",
  [ContractRow.freeAgentDesignation.NONE]: "None",
};

export const ContractFreeAgentStatusLabels = {
  [ContractRow.freeAgentStatus.UFA]: "Unrestricted Free Agent",
  [ContractRow.freeAgentStatus.RFA]: "Restricted Free Agent",
  [ContractRow.freeAgentStatus.NONE]: "None",
};

export const ContractPlayerConsentLabels = {
  [ContractRow.playerConsent.NONE]: "None",
  [ContractRow.playerConsent.NOTEN]: "Not Entered",
  [ContractRow.playerConsent.ROFRE]: "ROFR Exercised",
  [ContractRow.playerConsent.YEARK]: "1 Year K - Qual/Early Qual",
};

export const ContractSalaryOptionLabels = {
  [ContractSalaryRow.option.NONE]: "None",
  [ContractSalaryRow.option.PLYR]: "Player Opt",
  [ContractSalaryRow.option.TEAM]: "Team Opt",
  [ContractSalaryRow.option.ETO]: "Early Termination Opt",
  [ContractSalaryRow.option.PLYTF]: "Player Opt (Team Fav)",
  [ContractSalaryRow.option.TMPLY]: "Team/Player Opt",
};

export const ContractSalaryOptionDecisionLabels = {
  [ContractSalaryRow.optionDecision.DECL]: "Option Declined",
  [ContractSalaryRow.optionDecision.ETOCA]: "Canceled by ETO",
  [ContractSalaryRow.optionDecision.ETODE]: "ETO Deleted",
  [ContractSalaryRow.optionDecision.ETOEX]: "ETO Exercised",
  [ContractSalaryRow.optionDecision.EXER]: "Option Exercised",
  [ContractSalaryRow.optionDecision.NODEC]: "No Decision",
  [ContractSalaryRow.optionDecision.POD]: "Player Option Declined",
  [ContractSalaryRow.optionDecision.POE]: "Player Option Exercised",
  [ContractSalaryRow.optionDecision.TOCA]: "Canceled by TO",
  [ContractSalaryRow.optionDecision.TOD]: "Team Option Declined",
  [ContractSalaryRow.optionDecision.TOE]: "Team Option Exercised",
  [ContractSalaryRow.optionDecision.WETO]: "Waived - ETO",
  [ContractSalaryRow.optionDecision.WTOPT]: "Waived - Team Option",
  [ContractSalaryRow.optionDecision.WVPF]: "Waived - Player Favorable",
  [ContractSalaryRow.optionDecision.WVTF]: "Waived - Team Favorable",
};

export const ContractProtectionCoverageLabels = {
  [ContractProtectionRow.coverage.FULL]: "Full",
  [ContractProtectionRow.coverage.NOCND]: "None/Cond",
  [ContractProtectionRow.coverage.NONE]: "None",
  [ContractProtectionRow.coverage.PART]: "Partial",
  [ContractProtectionRow.coverage.PTCND]: "Part./Cond",
};

export const ContractBonusTypeLabels = {
  [ContractBonusRow.bonusType.LKLY]: "Likely",
  [ContractBonusRow.bonusType.OFFSE]: "Off-season Bonus",
  [ContractBonusRow.bonusType.PERF]: "Performance Bonus",
  [ContractBonusRow.bonusType.ULKLY]: "Unlikely",
};

export const ContractBonusEarnedLabels = {
  [ContractBonusRow.earned.ERN]: "Yes",
  [ContractBonusRow.earned.NTERN]: "No",
  [ContractBonusRow.earned.PEND]: "Pending",
};

export const ContractTradeRestrictionLabels = {
  [ContractRow.tradeRestriction.DEC15]: "December 15 / 3 Months",
  [ContractRow.tradeRestriction.JAN15]: "January 15 / 3 Months",
  [ContractRow.tradeRestriction._30DAY]: "30 Days",
  [ContractRow.tradeRestriction.NOTEN]: "Not Entered",
  [ContractRow.tradeRestriction.EX6MO]: "Extension - 6 months",
  [ContractRow.tradeRestriction.EXNON]: "Extension - None",
  [ContractRow.tradeRestriction.NA]: "N/A",
  [ContractRow.tradeRestriction.VETDP]: "DP Vet. - 1 Year",
};

export const ContractPaymentScheduleLabels = {
  [ContractRow.paymentSchedule._12SM]: "12 Equal Semi-Mo",
  [ContractRow.paymentSchedule._24SM]: "24 Equal Semi-Mo",
  [ContractRow.paymentSchedule._36SM]: "36 Equal Semi-Mo",
  [ContractRow.paymentSchedule.NOTEN]: "None",
  [ContractRow.paymentSchedule.OTH]: "Other",
};
