import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Grid,
  Container,
  Select,
  SelectOption,
  SkeletonHeading,
  Skeleton,
  Flex,
  Text,
} from "@suns/design-system";
import { useAsync, useUrlParams } from "@/shared/hooks";
import { GameCard, GameHero, GameHeroSkeleton } from "./components";
import { NbaLeagueId, SunsApiError } from "@suns/api";
import { Suns, ValleySuns } from "@/shared/const";
import { scheduleLoader, teamsLoader } from "./loaders";

export function GameDnaListings() {
  const [params, setParams] = useUrlParams(["nbaLeagueId", "nbaTeamId"], {
    nbaLeagueId: NbaLeagueId.NBA,
  });
  const { nbaLeagueId } = params;
  const nbaTeamId =
    params.nbaTeamId ||
    (nbaLeagueId == NbaLeagueId.NBA ? `${Suns.id}` : `${ValleySuns.id}`);
  const {
    loading: loadingSchedule,
    response: schedule,
    error: scheduleError,
  } = useAsync(scheduleLoader, {
    nbaLeagueId: nbaLeagueId as NbaLeagueId,
    nbaTeamId: nbaTeamId!,
  });
  const {
    loading: loadingTeams,
    response: teams,
    error: teamsError,
  } = useAsync(teamsLoader, {
    nbaLeagueId: nbaLeagueId as NbaLeagueId,
  });
  const [selectedTimeframe, setSelectedTimeframe] = useState<
    "upcoming" | "past"
  >("past");
  const filteredGames = useMemo(() => {
    if (!schedule) {
      return [];
    }
    let games =
      selectedTimeframe == "upcoming"
        ? schedule.upcomingGames
        : schedule.pastGames;

    games = games.filter((game) => {
      const homeTeamNbaId = game.homeTeamNbaId;
      const awayTeamNbaId = game.awayTeamNbaId;
      return `${homeTeamNbaId}` == nbaTeamId || `${awayTeamNbaId}` == nbaTeamId;
    });

    return games.sort((a, b) => {
      const dateA = new Date(a.gameDateTimeUtc);
      const dateB = new Date(b.gameDateTimeUtc);
      return selectedTimeframe === "upcoming"
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    });
  }, [schedule, nbaTeamId, selectedTimeframe]);

  if (loadingSchedule) {
    return <GameDnaListingsSkeleton />;
  }

  if (scheduleError) {
    throw new SunsApiError("Error loading schedule", { cause: scheduleError });
  }

  if (teamsError) {
    throw new SunsApiError("Error loading teams", { cause: teamsError });
  }

  const { nextGame } = schedule;
  return (
    <>
      {nextGame ? (
        <Container size="lg">
          <Link to={`/game/${nextGame.nbaGameId}/live`}>
            <Card
              badge="Next game"
              badgeProps={{ variant: "secondary" }}
              className="my-2"
            >
              <GameHero {...nextGame} />
            </Card>
          </Link>
        </Container>
      ) : null}
      {filteredGames ? (
        <Container size="lg">
          <Card>
            <Flex gap="sm" className="mb-6" wrap>
              <Select
                className="w-full md:w-32"
                value={nbaLeagueId ?? undefined}
                onValueChange={(value) => setParams({ nbaLeagueId: value })}
              >
                <SelectOption value={NbaLeagueId.NBA}>NBA</SelectOption>
                <SelectOption value={NbaLeagueId.GLEAGUE}>
                  G League
                </SelectOption>
              </Select>
              {loadingTeams ? (
                <Skeleton className="h-[40px] w-full md:w-32" />
              ) : (
                <Select
                  className="w-full md:w-32"
                  value={nbaTeamId}
                  onValueChange={(value) => {
                    setParams({ nbaTeamId: value, nbaLeagueId });
                  }}
                  placeholder="Select a team..."
                  disabled={loadingTeams}
                >
                  {teams?.map(({ nbaId, name }) => (
                    <SelectOption key={`option-${nbaId}`} value={`${nbaId}`}>
                      {name}
                    </SelectOption>
                  ))}
                </Select>
              )}
              <Select
                className="w-full md:w-32"
                value={selectedTimeframe}
                onValueChange={(value) =>
                  setSelectedTimeframe(value as "past" | "upcoming")
                }
              >
                <SelectOption value="past">Past games</SelectOption>
                <SelectOption value="upcoming">Upcoming games</SelectOption>
              </Select>
            </Flex>
            {filteredGames.length == 0 ? (
              <Text muted size="lg">
                No games found
              </Text>
            ) : (
              <Grid columns={["sm:2", "lg:3"]} gap="lg">
                {filteredGames.map((game) => (
                  <GameCard
                    key={`game-${game.nbaGameId}`}
                    nbaPrimaryTeamId={Number(nbaTeamId)}
                    {...game}
                  />
                ))}
              </Grid>
            )}
          </Card>
        </Container>
      ) : null}
    </>
  );
}

function GameDnaListingsSkeleton() {
  return (
    <>
      <Container size="lg">
        <Card
          badge="Next game"
          badgeProps={{ muted: true }}
          className="my-2 hover:bg-gray-50"
        >
          <GameHeroSkeleton />
        </Card>
      </Container>
      <Container size="lg">
        <Card header={<SkeletonHeading />}>
          <Grid columns="sm:2" gap="md">
            {[...Array(4).keys()].map((i) => (
              <Skeleton key={`sk-${i}`} className="h-[40px] w-full" />
            ))}
          </Grid>
        </Card>
      </Container>
    </>
  );
}
