import {
  cn,
  Flex,
  Skeleton,
  Select,
  SelectOption,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
} from "@suns/design-system";

import { Stats } from "../../pages/players/components/PlayersStats";
import { StatName } from "@/shared/const";
import { useParams } from "react-router-dom";
interface StatsTableProps {
  title: string;
  stats: Stats[];
  loading: boolean;
  error?: boolean | null;
  measureType: string;
  setMeasureType: (value: string) => void;
  perMode?: string;
  setPerMode?: (value: string) => void;
  playerLastNGames?: string;
  setPlayerLastNGames?: (value: string) => void;
  seasonType?: string;
  setSeasonType?: (value: string) => void;
}

export function StatsTable({
  title,
  stats,
  loading,
  error,
  measureType,
  setMeasureType,
  perMode,
  setPerMode,
  playerLastNGames,
  setPlayerLastNGames,
  seasonType,
  setSeasonType,
}: StatsTableProps) {
  const { teamId: nbaTeamId } = useParams();
  const measureTypeOptions = [
    { value: "Advanced", label: StatName.Advanced },
    { value: "Base", label: StatName.BoxScore },
    ...(!nbaTeamId ? [{ value: "Defense", label: StatName.Defense }] : []),
    { value: "Four Factors", label: StatName.FourFactors },
    { value: "Misc", label: StatName.Misc },
    { value: "Opponent", label: StatName.Opponent },
    { value: "Scoring", label: StatName.Scoring },
    ...(!nbaTeamId ? [{ value: "Usage", label: StatName.Usage }] : []),
  ];

  const perModeOptions = [
    { value: "PerGame", label: StatName.PerGame },
    { value: "Totals", label: StatName.Totals },
    {
      value: "Per100Possessions",
      label: StatName.Per100Possessions,
    },
    { value: "Per100Plays", label: StatName.Per100Plays },
    { value: "PerPossession", label: StatName.PerPossession },
    { value: "Per48Minutes", label: `${StatName.Per48} Minutes` },
    { value: "Per36Minutes", label: `${StatName.Per36} Minutes` },
    { value: "PerMinute", label: StatName.PerMinute },
    { value: "PerPlay", label: StatName.PerPlay },
  ];

  const seasonTypeOptions = [
    ...(title.toLowerCase().includes("nba")
      ? [{ value: "Pre Season", label: "Pre Season" }]
      : []),
    ...(title.toLowerCase().includes("g league")
      ? [{ value: "Showcase", label: "Showcase" }]
      : []),
    { value: "Regular Season", label: "Regular Season" },
    ...(title.toLowerCase().includes("nba")
      ? [{ value: "IST", label: "NBA Cup" }]
      : []),
  ];

  const lastNGamesOptions =
    seasonType === "IST"
      ? [{ value: "0", label: "All Games" }]
      : [
          { value: "0", label: "All Games" },
          { value: "5", label: "Last 5 Games" },
          { value: "20", label: "Last 20 Games" },
        ];

  return (
    <>
      {stats?.length || error ? (
        <Flex direction="down" gap="sm">
          <Text heading variant="muted">
            {title}
          </Text>
          <Flex gap="sm">
            {measureType ? (
              <Select
                value={measureType}
                onValueChange={(value) => setMeasureType(value)}
                className="bg-white"
              >
                {measureTypeOptions.map((option) => (
                  <SelectOption key={option.value} value={option.value}>
                    {option.label}
                  </SelectOption>
                ))}
              </Select>
            ) : null}
            {perMode ? (
              <Select
                value={perMode}
                onValueChange={(value) => setPerMode?.(value)}
                className="bg-white"
              >
                {perModeOptions.map((option) => (
                  <SelectOption key={option.value} value={option.value}>
                    {option.label}
                  </SelectOption>
                ))}
              </Select>
            ) : null}
            {seasonType ? (
              <Select
                value={seasonType}
                onValueChange={(value) => setSeasonType?.(value)}
                className="bg-white"
              >
                {seasonTypeOptions.map((option) => (
                  <SelectOption key={option.value} value={option.value}>
                    {option.label}
                  </SelectOption>
                ))}
              </Select>
            ) : null}
            {playerLastNGames ? (
              <Select
                value={playerLastNGames}
                onValueChange={(value) => setPlayerLastNGames?.(value)}
                className="bg-white"
              >
                {lastNGamesOptions.map((option) => (
                  <SelectOption key={option.value} value={option.value}>
                    {option.label}
                  </SelectOption>
                ))}
              </Select>
            ) : null}
          </Flex>
        </Flex>
      ) : null}
      {!loading ? (
        <>
          {error && (
            <Table>
              <TableHeader>
                <TableRow>
                  {stats &&
                    stats[0]?.statsHeaders?.map((heading, index) => (
                      <TableHead
                        className="whitespace-pre-wrap px-[0.85rem] text-[10px] font-bold"
                        key={heading + index}
                      >
                        {heading}
                      </TableHead>
                    ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow className="whitespace-normal">
                  <TableCell
                    colSpan={stats && stats[0]?.statsHeaders.length + 1}
                    className="w-full bg-gray-100 text-center"
                  >
                    There was an issue retrieving stats
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
          {stats?.length ? (
            <Table
              className={cn("rounded border", [
                "max-md:[&_td]:p-2",
                "max-md:[&_td]:text-sm",
                "max-md:[&_th]:p-2",
                "max-md:[&_th]:text-xs",
              ])}
            >
              <TableHeader>
                <TableRow>
                  {stats &&
                    stats[0]?.statsHeaders?.map((heading, index) => (
                      <TableHead
                        className="whitespace-normal px-[0.85rem] text-[10px] font-bold "
                        key={heading + index}
                      >
                        {heading}
                      </TableHead>
                    ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {(() => {
                  const seenPerColumn = new Map<number, Set<string>>();

                  return stats?.map((player, rowIndex) => (
                    <TableRow
                      key={`player-${rowIndex}`}
                      className={cn(
                        "whitespace-nowrap text-xs",
                        stats[stats.length - 1].stats.includes("Career") &&
                          rowIndex === stats.length - 1 &&
                          "font-bold",
                        rowIndex % 2 === 0 && "bg-gray-50"
                      )}
                    >
                      {player.stats?.map(
                        (stat: string | number | null, cellIndex: number) => {
                          const value = String(stat);
                          const seenSet =
                            seenPerColumn.get(cellIndex) || new Set<string>();
                          const isDuplicate = seenSet.has(value);

                          if (!isDuplicate && cellIndex === 0) {
                            seenSet.add(value);
                            seenPerColumn.set(cellIndex, seenSet);
                          }

                          return (
                            <TableCell key={`stat-${rowIndex}-${cellIndex}`}>
                              {isDuplicate ? "" : value}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  ));
                })()}
              </TableBody>
            </Table>
          ) : null}
        </>
      ) : (
        <SkeletonTable />
      )}
    </>
  );
}

export function SkeletonTable() {
  return (
    <Table
      className={cn("rounded border", [
        "max-md:[&_td]:p-2",
        "max-md:[&_td]:text-sm",
        "max-md:[&_th]:p-2",
        "max-md:[&_th]:text-xs",
      ])}
    >
      <TableHeader>
        <TableRow>
          {Array.from({ length: 18 }, (_, i) => i + 1).map((_, index) => (
            <TableHead className="px-[0.85rem]" key={index}>
              <Skeleton className="h-4 w-full" />
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          {Array.from({ length: 18 }, (_, i) => i + 1).map((_, index) => (
            <TableCell className={cn(index !== 0 && "border-l")} key={index}>
              <Skeleton className="h-4 w-10" />
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
}
