import { StatName } from "@/shared/const/glossary";
import { theme } from "@/shared/theme";
import { PlayerMetadataRow } from "@suns/api/generated-client/apollo";
import {
  Flex,
  Switch,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
} from "@suns/design-system";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@suns/design-system/src/components/Chart/Chart";
import { cn } from "@suns/design-system/src/utils/cn";
import dayjs from "dayjs";
import { useState } from "react";
import {
  CartesianGrid,
  Dot,
  Line,
  LineChart,
  ReferenceLine,
  XAxis,
  YAxis,
} from "recharts";
import { MetricButton } from "./MetricButton";
import { CURRENT_DATE } from "@/shared/const";
import {
  generateChartTicks,
  playerMetadataToYearlyChartData,
  roundToSignificantDigit,
} from "@/shared/utils/helper-functions";

const chartConfig = {
  ppv: {
    label: StatName.PPV,
    color: theme.colors.purple.DEFAULT,
  },
  oPpv: {
    label: StatName.OPPV,
    color: theme.colors.magenta.DEFAULT,
  },
  dPpv: {
    label: StatName.DPPV,
    color: theme.colors.teal.DEFAULT,
  },
} satisfies ChartConfig;

type MetricKey = keyof typeof chartConfig;

export default function PlayerPpvChart({
  playerMetadata,
}: {
  playerMetadata: PlayerMetadataRow[];
}) {
  const [activeChart, setActiveChart] = useState<
    keyof typeof chartConfig | null
  >(null);
  const [showAdjustedPpv, setShowAdjustedPpv] = useState(false);
  const chartDataMonthly = playerMetadata
    ?.filter((metadata) => {
      if (metadata.snapshotDate === null) return true;
      const snapshotDate = new Date(metadata.snapshotDate);
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 8);
      return snapshotDate >= startDate;
    })
    .map((metadata) => ({
      snapshotDate: metadata.snapshotDate
        ? dayjs(metadata.snapshotDate).format("MMM DD YYYY")
        : dayjs().format("MMM DD YYYY"),
      ppv: showAdjustedPpv
        ? metadata.adjustedPpv ?? metadata.ppv
        : metadata.ppv,
      oPpv: showAdjustedPpv
        ? metadata.adjustedOPpv ?? metadata.oPpv
        : metadata.oPpv,
      dPpv: showAdjustedPpv
        ? metadata.adjustedDPpv ?? metadata.dPpv
        : metadata.dPpv,
    }))
    .sort(
      (a, b) =>
        new Date(a.snapshotDate).getTime() - new Date(b.snapshotDate).getTime()
    )
    .filter((item, index, array) => {
      if (index === 0) return true;
      return item.snapshotDate !== array[index - 1].snapshotDate;
    });

  const chartDataAnnual = Object.values(
    playerMetadataToYearlyChartData(playerMetadata)
  )
    ?.filter((metadata) => metadata.ppv !== null)
    .sort((a, b) => {
      if (a.snapshotDate === null) return 1;
      if (b.snapshotDate === null) return -1;
      return (
        new Date(a.snapshotDate).getTime() - new Date(b.snapshotDate).getTime()
      );
    })
    .map((metadata) => ({
      snapshotDate: metadata.snapshotDate
        ? dayjs(metadata.snapshotDate).format("MMM YYYY")
        : dayjs().format("MMM YYYY"),
      ppv: showAdjustedPpv
        ? metadata.adjustedPpv ?? metadata.ppv
        : metadata.ppv,
      oPpv: showAdjustedPpv
        ? metadata.adjustedOPpv ?? metadata.oPpv
        : metadata.oPpv,
      dPpv: showAdjustedPpv
        ? metadata.adjustedDPpv ?? metadata.dPpv
        : metadata.dPpv,
    }));

  const onMetricClick = (metric: keyof typeof chartConfig) => {
    if (activeChart === metric) {
      setActiveChart(null);
    } else {
      setActiveChart(metric);
    }
  };

  const getMetricValues = (
    data: typeof chartDataAnnual,
    metric: MetricKey
  ) => ({
    currentValue: data[data.length - 1]?.[metric]?.toString() ?? null,
    previousValue:
      data.find((d) => d[metric] !== null)?.[metric]?.toString() ?? null,
  });

  return (
    <Flex direction="down" gap="md" className="w-full">
      <Tabs defaultValue="annual">
        <Flex direction="right" justify="between">
          <Flex direction="down" gap="sm">
            <Text heading muted size="lg">
              Player Performance Value ({StatName.PPV})
            </Text>
            <Flex direction="right" gap="sm" align="center">
              <Switch
                checked={showAdjustedPpv}
                onCheckedChange={setShowAdjustedPpv}
              />
              <Text size="xs" heading>
                Inflation Adjusted
              </Text>
            </Flex>
          </Flex>
          <TabsList>
            <TabsTrigger value="annual">Annual</TabsTrigger>
            <TabsTrigger value="monthly">Monthly</TabsTrigger>
          </TabsList>
        </Flex>
        <TabsContent value="annual">
          <Flex direction="down" gap="sm" className="w-full">
            <Flex direction="right" gap="sm" className="flex-wrap">
              {(
                Object.entries(chartConfig) as [
                  MetricKey,
                  (typeof chartConfig)[MetricKey],
                ][]
              ).map(([key, config]) => {
                const values = getMetricValues(chartDataAnnual, key);
                return (
                  <MetricButton
                    key={key}
                    label={config.label}
                    color={config.color}
                    currentValue={values.currentValue}
                    previousValue={values.previousValue}
                    differenceType="percentage"
                    active={activeChart === key}
                    onClick={() => onMetricClick(key)}
                  />
                );
              })}
            </Flex>
            <PpvChart
              timeframe="annual"
              chartData={chartDataAnnual.map((data) => ({
                ...data,
                snapshotDate: data.snapshotDate ?? "",
              }))}
              chartConfig={chartConfig}
              activeChart={activeChart}
            />
          </Flex>
        </TabsContent>
        <TabsContent value="monthly">
          <Flex direction="down" gap="sm" className="w-full">
            <Flex direction="right" gap="sm" className="flex-wrap">
              {(
                Object.entries(chartConfig) as [
                  MetricKey,
                  (typeof chartConfig)[MetricKey],
                ][]
              ).map(([key, config]) => {
                const values = getMetricValues(chartDataMonthly, key);
                return (
                  <MetricButton
                    key={key}
                    label={config.label}
                    color={config.color}
                    currentValue={values.currentValue}
                    previousValue={values.previousValue}
                    differenceType="percentage"
                    active={activeChart === key}
                    onClick={() => onMetricClick(key)}
                  />
                );
              })}
            </Flex>

            <PpvChart
              timeframe="monthly"
              chartData={chartDataMonthly.map((data) => ({
                ...data,
                snapshotDate: data.snapshotDate ?? "",
              }))}
              chartConfig={chartConfig}
              activeChart={activeChart}
            />
          </Flex>
        </TabsContent>
      </Tabs>
    </Flex>
  );
}

function PpvChart({
  chartData,
  chartConfig,
  timeframe,
  activeChart,
}: {
  chartData: {
    snapshotDate: string;
    ppv: number | null;
    oPpv: number | null;
    dPpv: number | null;
  }[];
  chartConfig: ChartConfig;
  timeframe: "monthly" | "annual";
  activeChart: keyof typeof chartConfig | null;
}) {
  const allChartValues = [
    ...chartData.map((d) => Number(d?.ppv)),
    ...chartData.map((d) => Number(d?.oPpv)),
    ...chartData.map((d) => Number(d?.dPpv)),
  ];

  const minValue = Math.min(
    0,
    roundToSignificantDigit(
      Math.min(...allChartValues) > 0
        ? Math.min(...allChartValues)
        : Math.min(...allChartValues) * 1.5
    )
  );

  const maxValue = roundToSignificantDigit(Math.max(...allChartValues) * 1.5);

  const ticks = generateChartTicks(
    minValue < 0 ? minValue : 0,
    maxValue > 0 ? maxValue : Math.abs(minValue)
  );

  return (
    <ChartContainer config={chartConfig} className="h-[400px] w-full md:h-full">
      <LineChart
        accessibilityLayer
        data={chartData}
        margin={{
          top: 12,
          left: 20,
          right: 12,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="snapshotDate"
          tickLine={false}
          axisLine={false}
          tickMargin={12}
          tickFormatter={(value) => {
            const isAnnual = timeframe === "annual";
            const dateFormat = isAnnual ? "MMM YYYY" : "MMM DD YYYY";
            const isPresent = value === CURRENT_DATE.format("MMM DD YYYY");
            return isPresent
              ? "Today"
              : dayjs(value, dateFormat).format(isAnnual ? "'YY" : "MMM YY");
          }}
          strokeWidth={2}
        />
        <YAxis
          width={30}
          interval={0}
          dataKey="ppv"
          type="number"
          domain={[minValue, maxValue]}
          ticks={ticks}
          tickFormatter={(value) => {
            return value.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              notation: "compact",
              compactDisplay: "short",
            });
          }}
          tickLine={false}
        />
        <ReferenceLine
          y={0}
          stroke={theme.colors.gray[800]}
          strokeWidth={1.5}
          strokeOpacity={0.65}
        />

        <ChartTooltip
          cursor={false}
          content={
            <ChartTooltipContent
              valueFormatter={(value) => {
                const USDollar = new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                });
                return USDollar.format(Number(value));
              }}
            />
          }
        />
        <ChartLegend content={<ChartLegendContent />} />
        {Object.entries(chartConfig).map(([key, config], index) => (
          <Line
            key={key + index}
            dataKey={key}
            type="monotone"
            stroke={config.color}
            className={cn(activeChart && activeChart !== key && "opacity-40")}
            strokeWidth={2}
            animationDuration={900}
            activeDot={{ r: 6 }}
            dot={({ payload, ...props }) => {
              return (
                <Dot
                  className={cn(
                    activeChart && activeChart !== key && "opacity-40"
                  )}
                  key={`${key}-${props.cx}-${props.cy}`}
                  r={3}
                  cx={props.cx}
                  cy={props.cy}
                  fill={config.color}
                  stroke={config.color}
                />
              );
            }}
          />
        ))}
      </LineChart>
    </ChartContainer>
  );
}
