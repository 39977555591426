import { useAsync, useUrlParams } from "@/shared/hooks";
import { useNavigate } from "react-router-dom";
import { Page } from "@/components";
import { Filter, Flex } from "@suns/design-system";
import { DataTable } from "@suns/design-system/src/components/DataTable/DataTable";
import { ColumnFilters } from "@/components/ColumnFilters/ColumnFilters";
import { VisibilityState } from "@tanstack/react-table";
import { SunsApiError } from "@suns/api";
import { teamsLoader } from "./loader/team-loader";
import { useMemo } from "react";
import { LeagueRow } from "@suns/api/generated-client/apollo";
import { useLeagues } from "../intel/hooks/useLeagues";
import { columns, sections } from "@/pages/teams/teams-columns";

const defaultVisibleColumns = ["fullName", "domesticLeagueId", "conference"];

const defaultParams = {
  page: "1",
  pageSize: "20",
  sort: "fullName",
  dir: "asc",
  leagueIds: "1",
};

export function TeamsListings() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useUrlParams(
    ["page", "pageSize", "sort", "dir", "leagueIds", "visible"],
    defaultParams
  );

  const sorting = [
    {
      id: searchParams.sort || "fullName",
      desc: searchParams.dir === "desc",
    },
  ];

  const pagination = {
    pageIndex: parseInt(searchParams.page || "1") - 1,
    pageSize: parseInt(searchParams.pageSize || "20"),
  };

  const leagueIdParams = searchParams.leagueIds;
  const leagueIds = leagueIdParams !== "all" ? leagueIdParams?.split(",") : [];

  const { response, loading, error } = useAsync(teamsLoader, {
    offset: pagination.pageIndex * pagination.pageSize,
    limit: pagination.pageSize,
    sortColumn: sorting[0].id,
    sortDir: sorting[0].desc ? "DESC" : "ASC",
    domesticLeagueId: leagueIds?.map(Number),
    includeDomesticLeague: true,
  });

  const {
    leagues,
    loading: leaguesLoading,
    error: leaguesError,
  } = useLeagues();

  const visibleColumns =
    searchParams.visible?.split(",") || defaultVisibleColumns;
  const columnVisibility = columns.reduce<VisibilityState>((acc, column) => {
    acc[column.accessorKey] = visibleColumns.includes(column.accessorKey);
    return acc;
  }, {});

  const filterLeaguesOptions = useMemo(
    () =>
      leagues?.map((league: LeagueRow) => ({
        key: league.id.toString(),
        name: league.name,
      })) || [],
    [leagues]
  );

  const handleFilterChange = (selectedKeys: string[]) => {
    setSearchParams({
      ...searchParams,
      leagueIds: selectedKeys.length ? selectedKeys.join(",") : "all",
    });
  };

  const handleSelectTeam = (id: number | null) => {
    if (!id) return;
    navigate(`${id}`);
  };

  if (error) {
    throw new SunsApiError("Unable to load teams.", {
      cause: error,
    });
  }

  if (leaguesError) {
    throw new SunsApiError("Error loading leagues", {
      cause: leaguesError,
    });
  }

  return (
    <Page title="Teams" breadcrumbs={false}>
      <Flex direction="down" gap="md" className="mt-4 lg:mt-2">
        <Flex direction="right" gap="md" justify="between">
          <Flex gap="md">
            <Filter
              title={leaguesLoading ? "Loading..." : "Leagues"}
              value={leagueIds}
              options={filterLeaguesOptions}
              onChange={handleFilterChange}
            />
            <ColumnFilters
              columns={columns}
              columnVisibility={columnVisibility}
              sections={sections}
              onChange={(column, checked) => {
                const newColumnVisibility = {
                  ...columnVisibility,
                  [column]: !checked,
                };

                setSearchParams({
                  ...searchParams,
                  visible: Object.keys(newColumnVisibility)
                    .filter((key) => Boolean(newColumnVisibility[key]))
                    .join(","),
                });
              }}
            />
          </Flex>
        </Flex>
        <DataTable
          loading={loading}
          columns={columns}
          data={response?.teams || []}
          count={response?.count}
          pagination={pagination}
          setPagination={(setPagination) => {
            const newPagination =
              typeof setPagination === "function"
                ? setPagination(pagination)
                : setPagination;

            setSearchParams({
              ...searchParams,
              page: `${newPagination.pageIndex + 1}`,
              pageSize: `${newPagination.pageSize}`,
            });
          }}
          sorting={sorting}
          setSorting={(setSorting) => {
            const newSort =
              typeof setSorting === "function"
                ? setSorting(sorting)
                : setSorting;

            const sort = newSort[0];

            setSearchParams({
              ...searchParams,
              sort: sort.id,
              dir: sort.desc ? "desc" : "asc",
            });
          }}
          columnVisibility={columnVisibility}
          onRowClicked={(row) => handleSelectTeam(row.original.id)}
        />
      </Flex>
    </Page>
  );
}
