import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export function useUrlParam(
  key: string
): [string | undefined, (value?: string) => void];
export function useUrlParam(
  key: string,
  defaultValue: string
): [string, (value?: string) => void];
export function useUrlParam(key: string, defaultValue?: string) {
  const [searchParams, setSearchParams] = useSearchParams();
  const param = useMemo(
    () => searchParams.get(key) || defaultValue,
    [searchParams, defaultValue, key]
  );

  function setParam(value?: string) {
    if (value == defaultValue || value === undefined) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }

    setSearchParams(searchParams);
  }

  return [param, setParam];
}

export function useUrlParams<
  T extends string,
  D extends Partial<Record<T, string>>,
  P = D & Record<Exclude<T, keyof D>, string | undefined>,
>(
  keys: T[],
  defaultParams?: D
): [P, (newParams?: Partial<Record<T, string>>) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo(
    () =>
      keys.reduce<P>((acc, key) => {
        const value = searchParams.get(key) ?? defaultParams?.[key];
        if (value !== undefined) {
          acc[key as unknown as keyof P] = value as P[keyof P];
        }
        return acc;
      }, {} as P),
    [defaultParams, keys, searchParams]
  );

  function setParams(newParams?: Partial<Record<T, string>>) {
    if (!newParams) {
      setSearchParams(new URLSearchParams());
      return;
    }

    const formattedParams = (Object.keys(newParams) as T[]).reduce(
      (acc, key) => {
        const value = newParams[key];
        if (value == defaultParams?.[key] || value === undefined) {
          acc.delete(key);
        } else {
          acc.set(key, value);
        }
        return acc;
      },
      new URLSearchParams()
    );
    setSearchParams(formattedParams);
  }

  return [params, setParams];
}
