import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AppLayout } from "@/layouts";
import { BoxScoreRoutes } from "./box-score-routes/box-score-routes";
import { GameDnaRoutes } from "./game-dna-routes/game-dna-routes";
import { TeamDnaRoutes } from "./team-dna-routes/team-dna-routes";
import LabsRoutes from "./labs-routes/labs";
import { Login } from "@/pages/login";
import { Home } from "@/pages/home";
import GlossaryRoutes from "./glossary-routes/glossary-routes";
import ReportRoutes from "./report-routes/report-routes";
import IntelRoutes from "./intel-routes/intel-routes";
import { Clear } from "@/pages/clear";
import { PlayersRoutes } from "./player-routes/players-routes";
import { TeamRoutes } from "./team-routes/team-routes";
import { PageNotFound } from "@/components";
import ErrorFallback from "@/components/error-fallback/error-fallback";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <PageNotFound />,
    children: [
      {
        index: true,
        element: <Home />,
        errorElement: <ErrorFallback />,
      },
      BoxScoreRoutes,
      GameDnaRoutes,
      TeamDnaRoutes,
      LabsRoutes,
      GlossaryRoutes,
      ReportRoutes,
      IntelRoutes,
      PlayersRoutes,
      TeamRoutes,
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/clear",
    element: <Clear />,
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
