export function oneAtATime<A extends unknown[], T>(
  fn: (...args: A) => Promise<T>
): (...args: A) => Promise<T> {
  let response: Promise<T> | null = null;

  return (...args: A) => {
    if (response != null) {
      return response;
    }

    response = fn(...args).finally(() => (response = null));
    return response;
  };
}
